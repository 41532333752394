import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { CallAction } from '../../models/callAction';
import {
    sendGroupCallAction, sendIndividualCallAction,
    masterPTT, addMastPTT, remMastPTT, sendConferenceEvent
} from '../../modules/communication';
import { getCallAction, getCallActionState, getCallName, getPriority, getCallieIdToShow, RenderIcon } from '../../utils/lib';
import { motion } from "framer-motion";

let pttOwner = '';

const ActiveCurrentCallCard = ({
    data, contactList, user, dial,
    isAmb, isBrd, isEmg,
    sendGroupCallAction, sendIndividualCallAction, sendConferenceEvent
}) => {

    //Speaking party / call action
    const [callAction, setCallAction] = useState('');
    const [colorReqd, setColor] = useState(false);
    const [isEmgCall, setEmgCall] = useState(isEmg);
    const [msg, setMsg] = useState('Trying...');
    const [actionItem, setAction] = useState({
        hold: false,
        mic: true,
        speaker: true,
        ptt: false,
        ispttTick: false,
        disconnectDis: false,
        holdDis: false,
        micDis: false,
        speakerDis: false,
        pttDis: false,
        pttReq: false,
    });
    const videoRef = useRef();
    const videoLocRef = useRef();
    const [videocall, setVideoCall] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [members, setMembers] = useState([]);
    const [memberstatus, setMemberstatus] = useState("");
    const [isHideIcon, setHideIcon] = useState(false);

    const getCallieName = (id) => {
        const callerId = getCallieIdToShow(id);
        const subDetails = contactList.filter(cont =>
            getCallieIdToShow(cont.mcptt_id) === callerId
        );
        if (subDetails.length > 0) return subDetails[0].contactName;
        else return getCallieIdToShow(id);
    }

    useEffect(() => {
        console.log('active big card effect.. ', data, data.talkingPartyId);
        const callState = getCallAction(data.stateType);
        let isCallInitialState = false;

        setAction(data.actionItem);
        if (dial) {
            if (data.stateType === 'RINGING' || data.stateType === 'TRYING' || data.stateType === 'WAITING') {
                isCallInitialState = true;
            } else {
                isCallInitialState = false;
            }
            if (data.stateType === 'RINGING') setMsg('Ringing...')
            if (data.stateType === 'TRYING') setMsg('Trying...')
            if (data.stateType === 'WAITING') setMsg('Waiting...')
        }
        if (!dial && data.actionItem['highlight']) {
            setTimeout(() => {
                const call = new CallAction(data, "DIS_HIGH");
                const stateup = getCallActionState("DIS_HIGH", data.actionItem);
                call.actionItem = stateup;
                if (data.callType.includes('GROUP')) sendGroupCallAction(user, call)
                else sendIndividualCallAction(user, call)
            }, 100);
        }
        // console.log('TALKING',data,actionItem)
        if (callState) {
            setCallAction(callState)
            setColor(true)
        } else {
            if (data.stateType == "UNHOLD") {
                setCallAction('')
            }
        }

        if (!dial && !isCallInitialState && data.talkingPartyId && data.talkingPartyId != '0') {
            if (getCallieIdToShow(data.talkingPartyId) == getCallieIdToShow(user.profile.mcptt_id)) {
                setCallAction('You are Speaking');
                pttOwner = 'self';
                setColor(false);
                if (!data.actionItem['ptt']) {
                    updateCallAction("PTT_GRANTED");
                }
            }
            else {
                console.log("call on other speaking block");
                const callie = '(' + getCallieName(data.talkingPartyId) + ') is speaking!';
                setCallAction(callie);
                pttOwner = 'other';
                setColor(false);
                if (data.actionItem['ptt']) {
                    updateCallAction("PTT_IGNORED");
                }
            }
        } else if (!isCallInitialState && data.talkingPartyId && data.talkingPartyId == '0') {
            console.log("call on other 0 block");
            if (data.stateType == "HOLD") {
                setCallAction(callState);
            } else {
                setCallAction('');
                pttOwner = '';
            }
            if (data.actionItem['pttReq'] || data.actionItem['ptt']) {
                updateCallAction("PTT_IGNORED");
            }
        }

        if (data.stateType === "CALL_UPGRADE") {
            console.log("CALL_UPGRADE UI ", data);
            if (data.callPriority && parseInt(data.callPriority) === 15) {
                setEmgCall(true);
            }
        }

        if (data.stateType === "CALL_MEMBERS") {
            console.log("CALL_MEMBERS UI ", data);
            if (data.members && data.members.length > 0) {
                let activeUsers = data.members.filter(member => member.status === "connected");
                if (activeUsers && activeUsers.length > 0) {
                    let msg = `${activeUsers.length} out of ${data.members.length} are active`;
                    setMemberstatus(msg);
                }
                setMembers(data.members);
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }

        const isVideo = data.isVideo ? JSON.parse(data.isVideo) : false;
        const isVideoconfig = global.config.video_call_config ? JSON.parse(global.config.video_call_config) : false;
        if (isVideo && isVideoconfig) {
            setTimeout(() => {
                const videoElement = videoRef.current;
                if (videoElement) {
                    if (data.callType === "DUPLEX_INDIVIDUAL_CALL") {
                        if (isCallInitialState) {
                            videoRef.current.srcObject = null;
                            if (videoLocRef.current) {
                                videoLocRef.current.srcObject = null;
                            }
                        } else if (data.actionItem['hold']) {
                            videoRef.current.srcObject = null;
                            if (videoLocRef.current) {
                                videoLocRef.current.srcObject = null;
                            }
                        }
                        else {
                            if (data.actionItem['speaker']) {
                                let remoteStrm = new MediaStream();
                                remoteStrm.addTrack(data.track);
                                videoRef.current.srcObject = remoteStrm;
                            } else {
                                videoRef.current.srcObject = null;
                            }
                            const videoLocElement = videoLocRef.current;
                            if (videoLocElement && data.localAudio) {
                                if (data.actionItem['mic']) {
                                    videoLocRef.current.srcObject = data.localAudio;
                                } else {
                                    videoLocRef.current.srcObject = null;
                                }
                            }
                        }
                    } else {
                        if (data.stateType == "HOLD" || isCallInitialState) {
                            videoRef.current.srcObject = null;
                        }
                        else if (pttOwner == 'self' && data.localAudio) {
                            videoRef.current.srcObject = data.localAudio;
                        } else {
                            if (pttOwner == 'other' && data.track) {
                                let remoteStrm = new MediaStream();
                                remoteStrm.addTrack(data.track);
                                videoRef.current.srcObject = remoteStrm;
                            } else {
                                videoRef.current.srcObject = null;
                            }
                        }
                    }
                }
            }, 100);
            setVideoCall(true);
        } else {
            setVideoCall(false);
        }

    }, [data])

    const updateCallAction = (type) => {
        const call = new CallAction(data, type);
        if (type === "CALL_SHRINK" || type === "DISCONNECTED") {
            if (videoRef && videoRef.current) {
                videoRef.current.srcObject = null;
            }
            if (videoLocRef && videoLocRef.current) {
                videoLocRef.current.srcObject = null;
            }
        }
        if (type !== "CALL_SHRINK") {
            const stateup = getCallActionState(type, data.actionItem);
            call.actionItem = stateup;
        }
        console.log('Active Call updateCallAction..', type, call)
        if (type === 'DISCONNECTED') {
            setIsOpen(false);
            if (user.profile.mcptt_id === data.toId) {
                if (Number(data.callPriority) === 15) {
                }
            }
        }

        if (type === 'RELEASE_PUSH_TO_TALK' || type === 'ACQUIRE_PUSH_TO_TALK') {
            call.fromId = user && user.profile.mcptt_id
        }
        if (type !== 'ACQUIRE_PUSH_TO_TALK') {
            if (call.callType.includes('GROUP')) sendGroupCallAction(user, call)
            else sendIndividualCallAction(user, call);
        }
        else if (data.stateType !== 'RELEASE_PUSH_TO_TALK') {
            if (call.callType.includes('GROUP')) sendGroupCallAction(user, call)
            else sendIndividualCallAction(user, call);
        }
    };

    const addConferenceEvent = () => {
        if (data.groupId) {
            sendConferenceEvent(data.groupId);
        }
    }

    const Priority = getPriority(parseInt(data.callPriority));
    let callieId = data.isFACall ? (data.fromId == global.config.activatedFA ? data.toId : data.fromId) : data.fromId === (user && user.profile.mcptt_id) ? data.toId : (data.groupId || data.fromId);
    let isCalled = data.isFACall ? (data.fromId === global.config.activatedFA) : (data.fromId === (user && user.profile.mcptt_id));

    let Name = data.isFACall ? callieId : getCallieName(callieId);

    let callTypeName = getCallName(data.callType);
    let isGroupCall = data.groupId && data.groupId.length > 0 ? true : false;
    let isDuplexCall = data.callType === "DUPLEX_INDIVIDUAL_CALL" ? true : false;

    if (global.config.activatedFA && global.config.activatedFA.length > 0) {
        if (data && data.fromId === global.config.activatedFA) {
            isCalled = true
            Name = data.toId
        }
    }
    if (data && data.forwardedId && data.forwardedId.length > 0) {
        Name = Name + ' -> ' + getCallieIdToShow(data.forwardedId);
    }

    let onProcessState = data ? ((data.stateType === 'RINGING' || data.stateType === 'TRYING' || data.stateType === 'WAITING') ? true : false) : false;
    let pttMsg = pttOwner.length === 0 ? "Tap to acquire PTT" : pttOwner === "self" ? "Tap to release PTT" : actionItem['pttDis'] ? "PTT acquiring" : "Tap to acquire PTT";
    //let isVideocallStarted = checkVideoCallStarted();
    //27AE60 3071F2 E89806

    return (
        <div className='act-current-call-main'>
            <motion.div
                animate={{ x: isOpen ? -15 : 1 }}
                transition={{ type: "spring", stiffness: 100 }}
                className='act-current-sub'
            >
                {
                    videocall === true ?
                        <video ref={videoRef} autoPlay id='video' onClick={() => setHideIcon(!isHideIcon)} />
                        : null
                }
                {
                    videocall === true && isDuplexCall &&
                    <div className='act-current-local-video'>
                        <video ref={videoLocRef} autoPlay id='videoLoc' />
                    </div>

                }
                <div className="act-current-expand-btn">
                    <button
                        className="btn mic_btn"
                        onClick={() => updateCallAction("CALL_SHRINK")}
                    >
                        <img src={'/assets/images/call-minimize.svg'} style={{ width: 28, height: 28, objectFit: 'contain' }} alt='' />
                    </button>
                </div>

                <motion.div
                    layout="position"
                    className='act-current-grp-disp'>
                    <img src={`/assets/images/${isGroupCall ? "grp-disp-icon" : "indvl-disp-icon"}.png`}></img>
                </motion.div>
                <motion.span layout="position" className='act-current-name'>{Name}</motion.span>
                <motion.div className='act-current-type'>
                    <div className='act-current-dir'>
                        <img
                            className="in-blc x18"
                            src={`/assets/images/${isCalled ? 'outgoingCall' : 'incomingCall'}.png`}
                            alt=''
                        />
                    </div>
                    <span className='act-current-call-type'>{callTypeName + " Call" + " (" + Priority + ")"}</span>
                </motion.div>
                {
                    isGroupCall &&
                    <motion.button
                        layout="position"
                        className='act-current-call-members'
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        name=""
                    >
                        {memberstatus}
                    </motion.button>
                }
                {
                    !isDuplexCall &&
                    <motion.span layout="position" className='act-current-call-ptt'>{callAction}</motion.span>
                }
                {!isDuplexCall && !isHideIcon &&
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        layout="position"
                        className='act-current-call-ptt-View'
                        style={isAmb || isBrd ? { backgroundColor: "#3071F2", pointerEvents: 'none' }
                            : actionItem['pttDis'] || dial ? { backgroundColor: "#E89806", pointerEvents: 'none' }
                                : pttOwner === "self" ? { backgroundColor: "#3071F2" }
                                    : { backgroundColor: "#27AE60" }}
                        onClick={() => actionItem['ptt'] ? updateCallAction("RELEASE_PUSH_TO_TALK") : updateCallAction("ACQUIRE_PUSH_TO_TALK")}>
                        <img className="in-blc x128" src={isBrd ? "/assets/images/broadcast.svg" : "/assets/images/Vector-4.svg"} alt='' />
                        <p className="act-current-call-ptt-status"><strong>{isBrd ? "BRD" : pttMsg}</strong></p>
                    </motion.button>}
                <div className='act-current-call-btns'>
                    {
                        !isHideIcon &&
                        <div
                            className='act-current-call-btns-subview1'>
                            <button
                                className="act-current-call-btns-type1"
                                onClick={() => actionItem['hold'] ? updateCallAction("UNHOLD") : updateCallAction("HOLD")}
                                type="button"
                                name=""
                                style={dial ? { pointerEvents: 'none', color: '#078041' } : actionItem['holdDis'] ? { pointerEvents: 'none', color: '#757575' } : {}}
                            >
                                {/* <img
                                src={`/assets/images/call-Hold.png`}
                                alt=""
                                className='x18'
                            /> */}
                                <img
                                    className='x18'
                                    src={`/assets/images/${actionItem['holdDis'] ? 'hold_disabled' : actionItem['hold'] ? 'unhold' : 'hold'}.svg`}
                                    alt=''
                                />
                            </button>

                            {/* <button
                            className="act-current-call-btns-type1"
                            onClick={() => updateCallAction("DISCONNECTED")}
                            type="button"
                            name=""
                        >
                            <img
                                src={`/assets/images/mic-mute.png`}
                                alt=""
                                style={{ width: 16, height: 21 }}
                            />
                        </button> */}

                            <button
                                className="act-current-call-btns-type1"
                                onClick={() => !actionItem['speaker'] ? updateCallAction("UNMUTE_SPEAKER") : updateCallAction("MUTE_SPEAKER")}
                                type="button"
                                style={actionItem['speakerDis'] ? { pointerEvents: 'none' } : {}}
                            >
                                {/* <img
                                src={`/assets/images/speaker-mute.png`}
                                style={{ width: 21, height: 18 }}
                                alt=""
                            /> */}
                                <img src={`/assets/images/${actionItem['speakerDis'] ? 'muted' : !actionItem['speaker'] ? 'mutePressed' : 'Group'}.svg`}
                                    alt=''
                                    style={{ width: 21, height: 18 }}
                                />
                            </button>

                            {
                                !isDuplexCall &&
                                <button
                                    className="act-current-call-btns-type1"
                                    onClick={() => addConferenceEvent()}
                                    type="button"
                                    name=""
                                >
                                    <img
                                        src={`/assets/images/conf-members.svg`}
                                        style={{ width: 32, height: 24 }}
                                        alt=""
                                    />
                                </button>
                            }

                            <button
                                className="act-current-call-btns-type1"
                                onClick={() => updateCallAction("CALL_SHRINK")}
                                type="button"
                                name=""
                            >
                                <img
                                    src={`/assets/images/${Priority === "EMG" ? "call-downgrade" : "call-upgrade"}.svg`}
                                    style={{ width: 36, height: 28 }}
                                    alt=""
                                />
                            </button>
                        </div>
                    }
                    <div className='act-current-call-btns-subview2'>
                        <button
                            className="act-current-call-btns-type2"
                            onClick={() => updateCallAction("DISCONNECTED")}
                            type="button"
                            name=""
                        >
                            <img
                                src={`/assets/images/call-discon.png`}
                                style={{ width: 33, height: 14 }}
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </motion.div>
            {
                isOpen &&
                <motion.div
                    animate={{ x: isOpen ? -10 : 0 }}
                    transition={{ type: "spring", stiffness: 100 }}
                    className='act-current-sub1'>
                    {
                        members.map((member) => {
                            return (
                                <div className='act-current-call-members-block'>
                                    {/* <div className='act-current-call-members-status' style={member.status === "connected" ? { backgroundColor: "#27AE60" } : { backgroundColor: "red" }}>
                                    </div> */}
                                    <div className="fav-icon" style={{ marginLeft: 12 }}>
                                        {RenderIcon("Dispatcher", member.status === "connected" ? "green" : "gray")}
                                    </div>
                                    <span className='f-contact-name white blc' style={{ marginLeft: 10, marginTop: 2 }}>{getCallieIdToShow(member.mcpttId)}</span>
                                </div>
                            )
                        })
                    }
                </motion.div>
            }

        </div>
    )

}

const mapStateToProps = ({ auth, communication }) => {
    const { user } = auth;
    const { contactList, initMptt } = communication;
    return {
        user, contactList, initMptt
    };
};

export default connect(mapStateToProps, {
    sendGroupCallAction, sendIndividualCallAction,
    masterPTT, addMastPTT, remMastPTT, sendConferenceEvent
})(ActiveCurrentCallCard)
