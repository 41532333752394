import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import PropTypes, { func } from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { sendIndividualCall, sendIndividualCallAction, sendTextMessage } from '../../modules/communication';
import { getFindMyDeviceEvent } from "../../modules/actions/mdmAction";
import { EditControl } from "react-leaflet-draw";
import { getCallieIdToShow } from '../../utils/lib';
import { HotKeys } from '../../components/commom';
import { setZoomLevel, setMapCenterPosition } from '../../modules/actions/locationAction'
import { IndividualCall } from '../../models/call';
import AskForDGNAPopup from "./AskForDGNAPopup";
import AskForCustomTime from "./AskForCustomTime";
import Errormesspopup from "./Errormesspopup";
import 'leaflet/dist/leaflet.css';
import { IndividualTextMessage } from '../../models/message'
import {
  MapContainer as Map,
  TileLayer,
  Marker,
  CircleMarker,
  Polyline,
  Popup,
  Tooltip,
  Polygon,
  FeatureGroup,
  LayersControl,
  LayerGroup,
  useMapEvents
} from "react-leaflet";
import L from "leaflet";
import {
  Modal,
  Button
} from "@material-ui/core";
import { EndPoints } from "../../MCXclient/endpoints";
const { BaseLayer, Overlay } = LayersControl;
const propTypes = {
  trains: PropTypes.array,
};

const faIcon = [
  {
    fa: "TR-DRIVER",
    activeIcon: "train-driver-active.png",
    inactiveIcon: "train-driver-inactive.png"
  },
  {
    fa: "TR-ASSTDRIVER",
    activeIcon: "assistant-driver-active.png",
    inactiveIcon: "assistant-driver-inactive.png"
  }
  , {
    fa: "TR-GUARD",
    activeIcon: "guard-active.png",
    inactiveIcon: "guard-inactive.png"
  }
  ,
  {
    fa: "TR-FRONTTRCP",
    activeIcon: "front-trcp-active.png",
    inactiveIcon: "front-trcp-inactive.png"
  },
  {
    fa: "TR-REARTRCP",
    activeIcon: "rear-trcp-active.png",
    inactiveIcon: "rear-trcp-inactive.png"
  }

]
const MapUEUsertype = [
  {
    ueType: "Consort-Handset",
    userType: "Driver",
    activeIcon: "handset-driver-active.png",
    inactiveIcon: "handset-driver-inactive.png"
  },
  {
    ueType: "Consort-Handset",
    userType: "Guard",
    activeIcon: "handset-guard-active.png",
    inactiveIcon: "handset-guard-inactive.png"
  },
  {
    ueType: "Consort-TRCP",
    userType: "Driver",
    activeIcon: "trcp-driver-active.png",
    inactiveIcon: "trcp-driver-inactive.png"
  }
]

var markerIcon = L.icon({
  iconUrl:
    "https://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-HD.png",
  iconSize: [32, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});



const FindMyDeviceIcon = () => {
  const circleStyle = {
    position: 'relative',
    background: 'radial-gradient(circle at center, #00ff00, #006400)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  };
  const circleStyle3 = {
    position: 'absolute',
    width: '15px',                  // Adjust size as needed
    height: '15px',                 // Adjust size as needed
    backgroundColor: '#0000ff',
    borderRadius: '50%',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  }
  const scanningEffectStyle = {
    position: 'absolute',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    background: 'rgba(0, 255, 0, 0.5)',
    animation: 'scanning 2s infinite',
    opacity: 0,
  };
  return (
    <div className="find-my-device-icon" style={circleStyle}>
      <div className="inner-circle-3" style={circleStyle3}></div>
      <div style={scanningEffectStyle}></div>
      <style>
        {`
          @keyframes scanning {
            0% {
              transform: scale(1);
              opacity: 0.5;
            }
            50% {
              transform: scale(1.5);
              opacity: 0;
            }
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};
const customIcon = L.divIcon({
  className: 'custom-icon',
  html: ReactDOMServer.renderToString(<FindMyDeviceIcon />),
  iconSize: [50, 50],
  iconAnchor: [25, 25],
});


const MapContainer = (props) => {


  const { POIrenderingList, zoomLevel, mapCenterPosition, setZoomLevel, setMapCenterPosition, setAddedLayerType, newFenceObj, setNewFenceObj,
    setisAskDGNAPopup, setisAddPopupOpen, fenceList, startGroupCall, sendIndividualCall, sendIndividualCallAction, navigateToCom,
    fencesCoords, fenceHighlight, filteredUsersList, newPOIObj, setNewPOIObj, user, isCallandSDSrequire, sendTextMessage, getFindMyDeviceEvent, findMyDeviceEvent
  } = props;

  const [mapKey, setMapKey] = useState(0);
  const [leafletObj, setLeafletObj] = useState(null);
  const [isCustomTime, setisCustomTime] = useState(false);
  const [isErrormessage, setisErrormessage] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [isFindDevice, setisFindDevice] = useState(false);
  const [isSnailTrail, setisSnailTrail] = useState(false);
  const [selectedsnailtrailcircle, setsnailtrailcircle] = useState([]);
  const [userDatafinddevice, setuserDatafinddevice] = useState({});
  const [center, setCenter] = useState(null);
  const [status, setStatus] = useState('IDLE');
  const [selectedsnailtrailpolyline, setsnailtrailpolyline] = useState([]);
  const [Timeobj, setTimeobj] = useState({
    start: new Date().getTime(),
    end: new Date().getTime()
  });
  const [onmycurrentloc, setmycurrentloc] = useState(false);
  const [userid, setuserid] = useState({});
  const [polyline, setPolyline] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(500);
  const [animate, setAnimate] = useState(false);
  const animationRef = useRef(null); // Adjust the speed in milliseconds
  const [showTooltip, setShowTooltip] = useState(true)
  const [isExpanded, setIsExpanded] = useState(true);
  const [isRinging,setIsRinging]=useState(false);
  const timeoutRef = useRef(null);

  function getDistanceThreshold(zoomLevel) {
    return {
      18: 10, 17: 20, 16: 40, 15: 60, 14: 100,
      13: 250, 12: 500, 11: 750, 10: 1000,
      9: 5000, 8: 10000, 7: 20000, 6: 40000,
      5: 80000, 4: 160000
    }[zoomLevel] || 1000;
  }

  useEffect(() => {
    setMapKey(prevKey => prevKey + 1);
  }, [fencesCoords, POIrenderingList])

 

  useEffect(() => {
    let out = getDistanceThreshold(zoomLevel);

    var reqObject = {
      "userName": userid.userName,
      "ueID": userid.ueID,
      "deviceId": "",
      "startTime": Timeobj.start,
      "endTime": Timeobj.end,
      "distanceBetweenPoints": out
    }


    if (isSnailTrail) {
      const res = axios
        .post(EndPoints.getConfig().getSnailTrail, reqObject)
        .then(
          (res) => {
            const data = res.data;
            const snailcircle = [];
            const poly = [];
            data.map((userData, index) => {
              let objdum = {
                "lat": userData.location.latitude,
                "lng": userData.location.longitude,
                "ts": new Date(userData.time_interval).toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }),

              }
              let polydum = [userData.location.latitude, userData.location.longitude];
              poly.push(polydum);
              snailcircle.push(objdum);


            })

            if (data.length == 0) {
              seterrormessage("No location Found for Given Input")
              setisErrormessage(true);
              setisSnailTrail(false);

              setsnailtrailcircle([]);
              setsnailtrailpolyline([]);
              setCurrentIndex(0);
              setPolyline([]);
              setShowTooltip(true);
              setIsPlaying(false);
              setAnimate(false);
              setCurrentPoint(null);

              setuserid({});
              setTimeobj({
                start: new Date().getTime(),
                end: new Date().getTime()
              })

            }
            else {
              setsnailtrailcircle(snailcircle);
              setsnailtrailpolyline(poly);
              setCurrentIndex(0);
              setPolyline([]);
              setShowTooltip(true);
              setAnimate(true);
              setCurrentPoint(snailcircle[0]);
              setMapCenterPosition([
                snailcircle[0].lat,
                snailcircle[0].lng,
              ]);

            }
          },
          (err) => {
            console.error("Err in Fetching snailTrail", err);
            seterrormessage("Some Error Occur During Fetch")
            setisErrormessage(true);
          }
        )
        .catch((err) => {
          console.error("Err in Fetching snailTrail", err);
          seterrormessage("Some Error Occur During Fetch")
          setisErrormessage(true);
        });
    }
  }, [zoomLevel, Timeobj, userid, isSnailTrail])




  //-----------------------------------------ALL predefined function----------------------------------------------




  const lastSeen = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    if (seconds > 0)
      return Math.floor(seconds) + " seconds";
    return "Just Now"
  }
  const getUeType = (ueType) => {
    if (ueType.includes('Handset')) {
      return 'Handset';
    }
    if (ueType.includes('TRCP')) {
      return 'TRCP';
    }
    return ueType;
  }
  function MapSetPosComponent() {
    const mapEvents = useMapEvents({
      zoomend: () => {
        if (zoomLevel != mapEvents.getZoom().toString()) {
          console.log("Inside Center", mapEvents.getZoom());
          setZoomLevel(mapEvents.getZoom().toString());
        }
        if (mapCenterPosition[0] != mapEvents.getCenter().lat || mapCenterPosition[1] != mapEvents.getCenter().lng) {
          let arr = [];
          arr.push(mapEvents.getCenter().lat);
          arr.push(mapEvents.getCenter().lng);
          setMapCenterPosition(arr);
        }
      },
      dragend: () => {
        if (mapCenterPosition[0] != mapEvents.getCenter().lat || mapCenterPosition[1] != mapEvents.getCenter().lng) {
          let arr = [];
          arr.push(mapEvents.getCenter().lat);
          arr.push(mapEvents.getCenter().lng);
          setMapCenterPosition(arr);
        }
      }
    });
    mapEvents.setView(mapCenterPosition, zoomLevel);
    return null
  }


  const _onCreate = (e) => {
    console.log(e);
    const { layerType, layer } = e;
    setAddedLayerType(layerType);
    if (layerType === "polygon") {
      const { _leaflet_id, _latlngs } = layer;
      console.log("fence latlngs", _latlngs);
      const leafletObj = {
        _leaflet_id: _leaflet_id,
        _latlng: _latlngs[0],
      };
      onAddLayerClick(leafletObj, layerType);
    }
    if (layerType === "marker") {
      const { _leaflet_id, _latlng } = layer;
      console.log("POI marker", _latlng);
      const leafletObj = {
        _leaflet_id: _leaflet_id,
        _latlng: _latlng,
      };
      onAddLayerClick(leafletObj, layerType);
      // setMapLayers(layers => [...layers,
      // { id: _leaflet_id, latlngs: layer.getLatLngs()[0] }])
    }
  };
  const onAddLayerClick = (leafletData, layerType) => {
    var { _leaflet_id, _latlng } = leafletData;
    if (layerType === "polygon") {
      console.log("POLYGON LEAFLET DATA", JSON.stringify(_latlng));

      setNewFenceObj({
        ...newFenceObj,
        leafletId: _leaflet_id,
        coordinates: _latlng,
      });
      setisAskDGNAPopup(true);
    } else if (layerType === "marker") {
      console.log("POI LEAFLET DATA", JSON.stringify([_latlng]));

      setNewPOIObj({
        ...newPOIObj,
        leafletId: _leaflet_id,
        coordinates: [_latlng],
      });
      setisAddPopupOpen(true);
    }

    setLeafletObj(leafletData);
  };
  const onMapPolygonClick = (clickIndex) => {
    if (fenceList[clickIndex]) {
      let selectedFence = fenceList[clickIndex];
      console.log(`Clicked on polygon ${JSON.stringify(selectedFence)}`);
      if (selectedFence.DGNA === 1 && selectedFence.groupID) {
        startGroupCall(selectedFence.groupID);
      } else {
        console.log("DGNA not enabled for this fence");
      }
    }
  };
  const isActive = (reportingInterval, lastUpdated) => {
    let timedeff = Math.floor((new Date() - lastUpdated) / 1000);
    if (timedeff > 5 * reportingInterval) {
      return false;
    }
    return true;
  }


  const getIconUrl = (userData) => {

    if (userData && userData.ActiveFA) {
      const faListIcon = userData.ActiveFA.split(',');
      for (let index = 0; index < faListIcon.length; index++) {
        const data = faListIcon[index];
        for (let index2 = 0; index2 < faIcon.length; index2++) {
          const fas = faIcon[index2];
          if (data.includes(fas.fa)) {
            console.log("faIcon Insdide fas", fas);
            if (isActive(userData.reportingInterval, userData.lastUpdated)) {
              return `assets/images/location/${fas.activeIcon}`;
            }
            else {
              return `assets/images/location/${fas.inactiveIcon}`;
            }

          }
        }
      }
    }


    if (userData && userData.ueType && userData.userType) {

      for (let index = 0; index < MapUEUsertype.length; index++) {
        const user = MapUEUsertype[index];
        if (user.ueType == userData.ueType && user.userType == userData.userType) {
          if (isActive(userData.reportingInterval, userData.lastUpdated)) {
            return `assets/images/location/${user.activeIcon}`;
          }
          else {
            return `assets/images/location/${user.inactiveIcon}`;
          }
        }

      }
    }
    if (isActive(userData.reportingInterval, userData.lastUpdated)) {
      return `assets/images/location/green-marker.png`;
    }
    else {
      return `assets/images/location/gray-marker.png`;
    }
  }

  const startCall = (userID, isFACall = false) => {
    console.log("Calling to ", userID);
    if (userID && userID.length > 0) {
      if (isFACall) {
        let faUserId = userID;
        let splitUserIdArr = userID.split(",");
        if (splitUserIdArr && splitUserIdArr.length > 0) {
          faUserId = splitUserIdArr[0];
        }
        let call = new IndividualCall('DUPLEX_INDIVIDUAL_CALL', faUserId, 'MEDIUM', isFACall, false);
        sendIndividualCall(user, call);
      } else {
        let call = new IndividualCall('DUPLEX_INDIVIDUAL_CALL', userID, 'MEDIUM', isFACall, false);
        sendIndividualCall(user, call);
      }
      navigateToCom();
      // updateTab('communication')
    }
  }
  const getIconsnail = () => {
    return `assets/images/location/green-marker.png`;
  }

  ///------------------------------------------------------End-------------------------------------------------------------
  // ------------------------------------------------Seek baar Snail Trail-------------------------------


  useEffect(() => {
    if (animate && isPlaying && currentIndex < selectedsnailtrailpolyline.length) {
      animationRef.current = setTimeout(() => {
        setPolyline((prevPolyline) => [
          ...prevPolyline,
          selectedsnailtrailpolyline[currentIndex],
        ]);
        setCurrentPoint(selectedsnailtrailcircle[currentIndex]);
        setMapCenterPosition([
          selectedsnailtrailcircle[currentIndex].lat,
          selectedsnailtrailcircle[currentIndex].lng,
        ]);
        setCurrentIndex(currentIndex + 1);

      }, animationSpeed);
      return () => clearTimeout(animationRef.current);
    }


  }, [isPlaying, animate, currentIndex, selectedsnailtrailpolyline, animationSpeed]);

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleChangeSpeed = (speedMultiplier) => {
    // Assuming 1x is 500ms, adjust this base value as needed
    if (speedMultiplier && animationSpeed >= 200) {
      let a = animationSpeed - 100;
      setAnimationSpeed(a);
    }
    if (!speedMultiplier && animationSpeed <= 700) {
      let a = animationSpeed + 100;
      setAnimationSpeed(a);
    }
  };
  const handleSeekBarChange = (event) => {

    const newIndex = parseInt(event.target.value);
    // setIsPlaying(false);
    setCurrentIndex(newIndex);
    setPolyline(selectedsnailtrailpolyline.slice(0, newIndex));
    setCurrentPoint(selectedsnailtrailcircle[newIndex - 1]);
    setAnimate(true);


  };

  const handleRewind = () => {
    setCurrentIndex(0);
    //  setIsPlaying(false);
    setPolyline([]);
    setCurrentPoint(selectedsnailtrailcircle[0]);

  };


  //------------------------------------------------------End----------------------------------------





  const handlemodal = (userData) => {
    setuserid(userData)
    setisCustomTime(true);
  }
  const handleCustomModel = () => {
    setisCustomTime(false);

  };
  const handleErrorModel = () => {
    setisErrormessage(false);
  }

  const setendstarttime = (timeobj) => {
    setTimeobj(timeobj);
    setisCustomTime(false);
    setisSnailTrail(true);
    setisFindDevice(false);

  };


  const handleTime = (userData, timerange) => {

    const now = new Date();
    let earliertime = now.getTime();
    if (timerange == "onehr") {
      earliertime = new Date(now.getTime() - (1 * 60 * 60 * 1000));
    }
    if (timerange == "oneday") {
      earliertime = new Date(now.getTime() - (1 * 24 * 60 * 60 * 1000));
    }
    if (timerange == "sevenday") {
      earliertime = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
    }
    const timeobj = {
      start: earliertime.getTime(),
      end: now.getTime()
    };
    setuserid(userData)
    setTimeobj(timeobj);
    setisFindDevice(false);
    setisSnailTrail(true);
  }



  const controlsStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '6px 0',
  };
  const buttonStyle = {
    padding: '8px 12px',
    backgroundColor: '#ffb01f',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };


  const rangeInputStyle = {
    WebkitAppearance: 'none',
    width: '90%',
    height: '8px',
    // background: `grey`,
    borderRadius: '5px',
    outline: 'none',
    opacity: 0.7,
    transition: 'opacity 0.2s',
    position: 'relative',
  };


  const startTime = currentPoint ? currentPoint.ts : selectedsnailtrailcircle.length > 0 ? selectedsnailtrailcircle[0].ts : '00:00';
  const endTime = new Date(Timeobj.end).toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

  ///------------------ Find My Device Card

  useEffect(() => {
      if(isFindDevice && userDatafinddevice && findMyDeviceEvent && (findMyDeviceEvent.deviceId==userDatafinddevice.deviceId) && (findMyDeviceEvent.event=="START_RINGING")){
        setStatus("REQUEST INITIATED");
        setIsRinging(true);
        timeoutRef.current= setTimeout(() => {
          setStatus("IDLE");
          setIsRinging(false);
        }, 300000); 

      }
    
   
      if(isFindDevice && userDatafinddevice && findMyDeviceEvent && (findMyDeviceEvent.deviceId==userDatafinddevice.deviceId) && (findMyDeviceEvent.event=="STOP_RINGING")){
        setStatus("IDLE");
        setIsRinging(false);
        clearTimeout(timeoutRef.current);
        
      }
    
    
  }, [findMyDeviceEvent]);

  const handleFindMyDeviceEvent=(event,findmydeviecID)=>{
    if(event && findmydeviecID) getFindMyDeviceEvent({deviceId:findmydeviecID,event:event});

  }

  const handleFindDevice = (userData) => {
    setMapCenterPosition([
      userData.message.latitude.location,
      userData.message.longitude.location,
    ]);

    setZoomLevel(15);

    setCenter([
      userData.message.latitude.location,
      userData.message.longitude.location,
    ])

    setisFindDevice(true);
    setCurrentIndex(0);
    setPolyline([]);
    setAnimate(false);
    setisSnailTrail(false);
    setIsPlaying(false)
    setuserDatafinddevice(userData);
  }
  const handlemycurrloc = (userData) => {
    setMapCenterPosition([
      userData.message.latitude.location,
      userData.message.longitude.location,
    ]);
    setmycurrentloc((prev) => !prev);
    setZoomLevel(15);

  }





  const handleofffindmydevice = () => {
    setisFindDevice(false);
    setuserDatafinddevice({});

    setCurrentIndex(0);
    setCurrentPoint(null);
    setsnailtrailcircle([]);
    setsnailtrailpolyline([]);
    setuserid({})
    setPolyline([]);
    setAnimate(false);
    setisSnailTrail(false);
setStatus("IDLE");
setIsRinging(false);
clearTimeout(timeoutRef.current);
    setZoomLevel(5);
    setShowTooltip(true);
    setIsPlaying(false);

    setisErrormessage(false);
    seterrormessage('');
    setAnimationSpeed(500);
    setmycurrentloc(false);

  }


  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  const dropdownStyle = {
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-out',
  };


  return (

    < div style={{ position: 'relative' }} >
      <style>
        {`
          input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            background: #ffb01f;
            cursor: pointer;
            border-radius: 50%;
            border: 2px solid #ffb01f;
            position: relative;
            z-index: 2;
          }

          input[type=range]::-moz-range-thumb {
            width: 15px;
            height: 15px;
            background: #ffb01f;
            cursor: pointer;
            border-radius: 50%;
            border: 2px solid #ffb01f;
            position: relative;
            z-index: 2;
          }
        `}
      </style>
      <Modal
        open={isCustomTime}
        onClose={handleCustomModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AskForCustomTime setendstarttime={setendstarttime} />
      </Modal>
      <Modal
        open={isErrormessage}
        onClose={handleErrorModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Errormesspopup message={errormessage} handleErrorModel={handleErrorModel} handleofffindmydevice={handleofffindmydevice} />
      </Modal>
      {(isSnailTrail && animate && !isFindDevice) ?
        <div style={controlsStyle}>
          <button
            style={buttonStyle}
            onClick={handlePlayPause}
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
          <button
            style={buttonStyle}
            onClick={() => handleChangeSpeed(1)}
          >
            +1X
          </button>
          <button
            style={buttonStyle}
            onClick={() => handleChangeSpeed(0)}
          >
            -1X
          </button>
          <button
            style={buttonStyle}
            onClick={handleRewind}
          >
            Rewind
          </button>
          <div style={{ position: 'relative', width: '100%', top: '3px' }}>
            <input
              type="range"
              min="0"
              max={selectedsnailtrailcircle.length}
              //   value={currentPoint?((new Date(currentPoint.ts).getTime()-Timeobj.start) / (Timeobj.end-Timeobj.start)) * 100:0}
              value={currentIndex}
              onChange={handleSeekBarChange}
              // style={rangeInputStyle}
              style={{ ...rangeInputStyle, background: `linear-gradient(to right, #ffb01f ${(currentIndex / (selectedsnailtrailcircle.length)) * 100}%, grey ${(currentIndex / (selectedsnailtrailcircle.length)) * 100}%)`, cursor: "pointer" }}

            />

            <span style={{ position: 'absolute', left: 0, top: '-20px', fontSize: '16px', color: 'white' }}>
              {startTime}
            </span>
            <span style={{ position: 'absolute', right: 0, top: '-20px', fontSize: '16px', color: ' white' }}>
              {endTime}
            </span>
          </div>
          <button
            style={buttonStyle}
            onClick={() => handleofffindmydevice()}
          >
            Exit
          </button>
        </div> : null}

      <Map
        zoom={zoomLevel}
        center={mapCenterPosition}
        scrollWheelZoom={true}
        maxZoom={18}
        minZoom={4}
        key={mapKey}
        style={{ height: "100vh", width: "100%" }}
      >

        <MapSetPosComponent />
        <TileLayer
          url={`https://${process.env.REACT_APP_OSM_HOST}:${process.env.REACT_APP_OSM_PORT}/tile/{z}/{x}/{y}.png`}
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //url="https://192.168.1.220:30802/tile/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <LayersControl>
          {isCallandSDSrequire ? <Overlay checked name="Fences">
            <FeatureGroup>

              <EditControl
                position="bottomright"
                //onEdited={_onEditPath}
                onCreated={_onCreate}
                //onDeleted={_onDelete}
                draw={{
                  rectangle: false,
                  circle: false,
                  polyline: false,
                  circlemarker: false,
                }}
                edit={{
                  remove: false,
                  edit: false
                }}
              />


              {fencesCoords &&
                fencesCoords.map((fence, index) => {

                  return (
                    <Polygon
                      color={fenceHighlight == index ? 'red' : 'green'}
                      key={index}
                      fillColor="yellow"
                      positions={fence}
                      onClick={() => onMapPolygonClick(index)}
                    ></Polygon>
                  );
                })}
            </FeatureGroup>
          </Overlay> : null}

          <Overlay checked name="SnailTrail">
            {(isSnailTrail && animate && !isFindDevice) ? <LayerGroup>


              {polyline.length > 1 && (
                <Polyline positions={polyline} color="grey" />
              )}
              {currentPoint && (
                <Marker position={[currentPoint.lat, currentPoint.lng]} icon={L.icon({
                  iconUrl: getIconsnail(),
                  iconSize: [25, 25],
                })}>
                  {showTooltip && (
                    <Tooltip permanent>
                      {currentPoint.ts}
                    </Tooltip>
                  )}
                  <Popup>
                    <div>
                      <h2>Seen here at {currentPoint.ts}</h2>

                      <div class="loctn-PopUp-Communicatn-Card">
                        <button onClick={() => handleofffindmydevice()}>Exit</button>

                        <button onClick={() => { setShowTooltip((prevShowTooltip) => !prevShowTooltip) }}>{showTooltip ? "Toggle Time" : "Show Time"}</button>
                      </div>

                    </div>
                  </Popup>
                </Marker>
              )}


            </LayerGroup> : null}
            {(isFindDevice && userDatafinddevice && !animate) ? <LayerGroup>
              <Marker position={center} icon={customIcon}>
                <Popup>
                  <h2>{getCallieIdToShow(userDatafinddevice.userName)} {userDatafinddevice.userType} {getUeType(userDatafinddevice.ueType)}</h2>
                  {/* {(userData.ActiveFA != "") ? <h2>{userData.ActiveFA}</h2> : null} */}
                  <h2>Last Seen:- {lastSeen(userDatafinddevice.lastUpdated)}</h2>
                  <div class="loctn-PopUp-Communicatn-Card">
                    <button onClick={() => handleofffindmydevice()}>Exit</button>


                    <DropdownButton id="custom-dropdown-button" variant="success" title="Trail">


                      <Dropdown.Item class="dropdown-card" onClick={() => handleTime(userDatafinddevice, "onehr")}>1 Hr</Dropdown.Item>
                      <Dropdown.Item class="dropdown-card" onClick={() => handleTime(userDatafinddevice, "oneday")}>1 Day</Dropdown.Item>
                      <Dropdown.Item class="dropdown-card" onClick={() => handleTime(userDatafinddevice, "sevenday")}>7 Day</Dropdown.Item>
                      <Dropdown.Item onClick={() => handlemodal(userDatafinddevice)} class="dropdown-card">Custom</Dropdown.Item>



                    </DropdownButton>


                  </div>

                </Popup>
              </Marker>

            </LayerGroup> : null}
          </Overlay>

          {(isSnailTrail && animate && !isFindDevice) ? <Overlay name="Show Points">
            <LayerGroup>
              {selectedsnailtrailcircle && currentIndex &&
                selectedsnailtrailcircle.slice(0, currentIndex - 1).map((user, idx) => {
                  return (
                    <CircleMarker center={[user.lat, user.lng]} radius={5}
                      pathOptions={{
                        fillColor: '#ffb01f',
                        color: '#ffb01f',
                        fillOpacity: 1,
                        weight: 1,
                      }} >
                      <Popup>
                        <h2>Seen here at: {user.ts} </h2>
                        <h2>lat:{user.lat}</h2>
                        <h2>lng:{user.lng}</h2>
                      </Popup>
                    </CircleMarker>
                  )


                })
              }

            </LayerGroup>
          </Overlay> : null}

          {(!isFindDevice && !animate) ? <Overlay checked name="Users">
            <LayerGroup>
              {filteredUsersList &&
                filteredUsersList.map((userData, index) => {
                  if (userData.message !== "") {
                    return (
                      <Marker
                        key={index}
                        icon={L.icon({
                          iconUrl: getIconUrl(userData),
                          iconSize: [45, 45],
                        })}
                        position={[
                          userData.message.latitude.location,
                          userData.message.longitude.location,
                        ]}
                      >
                        <Popup>
                          <div>
                            <h2>{getCallieIdToShow(userData.userName)} {userData.userType} {getUeType(userData.ueType)}</h2>
                            {(userData.ActiveFA != "") ? <h2>{userData.ActiveFA}</h2> : null}
                            <h2>
                              Last Seen:- {lastSeen(userData.lastUpdated)}</h2>
                            {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                            {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                            {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                              <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                              <button>
                                <HotKeys
                                  divclass='f4'
                                  title='Send SDS'
                                  type='SDS'
                                  sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                />
                              </button>


                              <DropdownButton
                                id="custom-dropdown-button"
                                variant="success"
                                title="Location">


                                {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>
                                <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                  <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                  <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                  <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                  <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                </DropdownButton >


                              </DropdownButton>

                            </div> : null}
                            {!isCallandSDSrequire ?
                              <div class="loctn-PopUp-location-Card">
                                <DropdownButton id="custom-dropdown-button"
                                  variant="success"
                                  title="Location" >


                                  <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                  <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>
                                  <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                    <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                    <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                  </DropdownButton>


                                </DropdownButton>
                              </div>
                              : null}
                            {filteredUsersList.map((user, idx) => {
                              if (idx != index) {
                                if (zoomLevel == 20 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.000025) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.000025))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>
                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>
                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 19 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.00005) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.00005))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>
                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 18 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.000125) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.000125))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 17 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.00025) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.00025))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 16 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.0005) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.0005))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 15 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.001) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.001))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {/* <Button style={{ marginLeft: "100px", backgroundColor: "#1FAC4C", color: "white" }} variant="contained" onClick={() => startCall(user.ActiveFA ? user.ActiveFA : user.mcpttUri, user.ActiveFA ? true : false)} >Start call</Button> */}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 14 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.0025) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.0025))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {/* <Button style={{ marginLeft: "100px", backgroundColor: "#1FAC4C", color: "white" }} variant="contained" onClick={() => startCall(user.mcpttUri)} >Start call</Button> */}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 13 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.005) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.005))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {/* <Button style={{ marginLeft: "100px", backgroundColor: "#1FAC4C", color: "white" }} variant="contained" onClick={() => startCall(user.mcpttUri)} >Start call</Button> */}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                if (zoomLevel == 12 && ((Math.abs(user.message.latitude.location - userData.message.latitude.location) <= 0.011) && (Math.abs(user.message.longitude.location - userData.message.longitude.location) <= 0.011))) {
                                  return <div>
                                    <h2>{getCallieIdToShow(user.userName)} {user.userType} {getUeType(user.ueType)}</h2>
                                    {(user.ActiveFA != "") ? <h2>{user.ActiveFA}</h2> : null}
                                    <h2>Last Seen:- {lastSeen(user.lastUpdated)}</h2>
                                    {onmycurrentloc ? <h2>Current Lat:- {userData.message.latitude.location}</h2> : null}
                                    {onmycurrentloc ? <h2>Current Lng:- {userData.message.longitude.location}</h2> : null}
                                    {/* <Button style={{ marginLeft: "100px", backgroundColor: "#1FAC4C", color: "white" }} variant="contained" onClick={() => startCall(user.mcpttUri)} >Start call</Button> */}
                                    {isCallandSDSrequire ? <div class="loctn-PopUp-Communicatn-Card">
                                      <button onClick={() => startCall(userData.ActiveFA ? userData.ActiveFA : userData.mcpttUri, userData.ActiveFA ? true : false)} >Start call</button>

                                      <button>
                                        <HotKeys
                                          divclass='f4'
                                          // Icon='feather icon-mail'
                                          title='Send SDS'
                                          type='SDS'
                                          sub={{ contactName: userData.userName, mcptt_uri: userData.mcpttUri, tetra_id: userData.userId, mcptt_id: userData.mcpttUri }}
                                        />
                                      </button>

                                      <DropdownButton
                                        id="custom-dropdown-button"
                                        variant="success"
                                        title="Location">


                                        {/* <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item> */}
                                        <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                        <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                          <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                          <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                          <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                          <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                        </DropdownButton >


                                      </DropdownButton>

                                    </div> : null}
                                    {!isCallandSDSrequire ?
                                      <div class="loctn-PopUp-location-Card">
                                        <DropdownButton id="custom-dropdown-button"
                                          variant="success"
                                          title="Location" >


                                          <Dropdown.Item href="#/action-3" class="dropdown-card" onClick={() => handleFindDevice(userData)}>Find My Device</Dropdown.Item>
                                          <Dropdown.Item class="dropdown-card" onClick={() => handlemycurrloc(userData)}>{!onmycurrentloc ? "On Current Location" : "Off Current Location"}</Dropdown.Item>

                                          <DropdownButton id="custom-dropdown-button" variant="success" title="Trail" style={{ width: "100%" }}>
                                            <Dropdown.Item eventKey="1" class="dropdown-card" onClick={() => handleTime(userData, "onehr")}>1 Hr</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" class="dropdown-card" onClick={() => handleTime(userData, "oneday")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" class="dropdown-card" onClick={() => handleTime(userData, "sevenday")}>7 Day</Dropdown.Item>

                                            <Dropdown.Item eventKey="4" onClick={() => handlemodal(userData)} class="dropdown-card">Custom</Dropdown.Item>
                                          </DropdownButton>


                                        </DropdownButton>
                                      </div>
                                      : null}
                                  </div>
                                }
                                return null
                              }
                            })}
                          </div>
                        </Popup>
                      </Marker>
                    );
                  }
                })}
            </LayerGroup>
          </Overlay> : null}
          {isCallandSDSrequire && !animate && !isFindDevice && <Overlay checked name="POI">
            <LayerGroup>
              {POIrenderingList &&
                POIrenderingList.map((poiData, index) => (
                  <Marker
                    key={index}
                    icon={L.icon({
                      iconUrl: "https://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-HD.png",
                      iconSize: [32, 41],
                      iconAnchor: [12.5, 41],
                      popupAnchor: [0, -41],
                    })}

                    position={[
                      JSON.parse(poiData.coordinates)[0].lat,
                      JSON.parse(poiData.coordinates)[0].lng,
                    ]}
                  >
                    <Popup>
                      <div>
                        <h2>{poiData.title}</h2>

                      </div>
                    </Popup>
                  </Marker>
                ))}
            </LayerGroup>
          </Overlay>}
        </LayersControl>
      </Map>
      {(isFindDevice && userDatafinddevice && !animate) ?

        <div class="find-my-device-card" >
          <div class="find-my-device-card-header">
            <div>
              <span style={{ fontWeight: "800" }}>Last Seen:</span>
              <span style={{ marginLeft: "5px", fontWeight: "800" }}>{lastSeen(userDatafinddevice.lastUpdated)}</span>
            </div>
            <button class="find-my-device-toggle-button " onClick={toggleDetails}>
              {isExpanded ? <img src={`assets/images/svg-icon/arrowup.svg`} alt="" /> : <img src={`assets/images/svg-icon/arrowdown.svg`} alt="" />}
            </button></div>

          <div style={{ ...dropdownStyle, maxHeight: isExpanded ? '450px' : '0' }}>

            <div class="find-my-device-detail" >
              < span style={{ fontWeight: "650" }}>Device Type:</span>
              <span >{userDatafinddevice.userType}</span>
            </div>
            < div class="find-my-device-detail">
              < span style={{ fontWeight: "650" }}>Device UserName:</span>
              <span >{userDatafinddevice.userName}</span>
            </div>
            <div class="find-my-device-detail">
              < span style={{ fontWeight: "650" }}>Nearest Area:</span>
              <span style={{ marginLeft: "8px" }}>NA</span>
            </div>
            <div class="find-my-device-detail">
              < span style={{ fontWeight: "650" }}>Latitude:</span>
              <span style={{ marginLeft: "8px" }}>{userDatafinddevice.message.latitude.location}</span>
            </div>
            <div class="find-my-device-detail">
              < span style={{ fontWeight: "650" }}>Longitude:</span>
              <span style={{ marginLeft: "8px" }}>{userDatafinddevice.message.longitude.location}</span>
            </div>

            < div style={{ display: "flex", justifyContent: "space-between" }}>
             {!isRinging? <button class="find-my-device-event-button" onClick={()=>handleFindMyDeviceEvent("START_RINGING",userDatafinddevice.deviceId)} >Start Ringing</button>:null}
             {isRinging? <button class="find-my-device-event-button" onClick={()=>handleFindMyDeviceEvent("STOP_RINGING",userDatafinddevice.deviceId)} >Stop Ringing</button>:null}

              <span class="find-my-device-status">{status}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button class="find-my-device-event-button" onClick={()=>handleFindMyDeviceEvent("MARK_LOST",userDatafinddevice.deviceId)} >Mark as Lost</button>
              < button class="find-my-device-event-button" onClick={()=>handleFindMyDeviceEvent("FORMAT_DEVICE",userDatafinddevice.deviceId)} >Format</button>
            </div>



            {/* <button  onClick={startRinging}>
            {countdown !== null ? `Cancel` : 'Start Ringing'}
          </button>
            <span >{status}</span>*/}

          </div>


        </div> : null}
    </div>
  )

}

const mapStateToProps = ({ auth, location, mdm }) => {
  const { user } = auth;
  const { zoomLevel, mapCenterPosition, fenceHighlight } = location;
  const { findMyDeviceEvent } = mdm;

  return {
    fenceHighlight,
    zoomLevel,
    mapCenterPosition,
    user,
    findMyDeviceEvent
  };
};

export default connect(mapStateToProps, {

  sendIndividualCall,
  sendTextMessage,
  sendIndividualCallAction,
  navigateToCom: () => push("/communication"),
  setMapCenterPosition,
  setZoomLevel,
  getFindMyDeviceEvent,

})(MapContainer);
