(function (exports) {
  const Utils = require("../jssip/JsSIP").Utils;
  const JsSIP_C = require("../jssip/JsSIP").C;
  const js2xmlparser = require("js2xmlparser");
  const OutgoingRequest = require("../sipMethods/OutgoingRequest")
    .OutgoingRequest;
  const RequestSender = require("../jssip/RequestSender");
  const debug = require("debug")("MCXClient:Registrator");
  const URI = require("../jssip/URI");
  const MIN_REGISTER_EXPIRES = 20; // In seconds.
  const deltaTimeoutVal = 10;
  const MAX_REGISTER_EXPIRES = 310;

  class Registrator {
    constructor(config, ua, transport) {
      const reg_id = 1; // Force reg_id to 1.

      this._ua = ua;
      this._transport = transport;

      this._registrar =
        config.registrar_server && config.registrar_server.includes("sip:")
          ? `${config.registrar_server}`
          : `sip:${config.registrar_server}`; //ua.configuration.registrar_server;
      this._expires = config.register_expires; //ua.configuration.register_expires;
      console.log("register expires constructor.....", this._expires);
      // Call-ID and CSeq values RFC3261 10.2.
      this._call_id = Utils.createRandomToken(22);
      this._cseq = 0;

      this.instance_id = `urn:uuid:${this._ua.configuration.instance_id}`;

      this._from_uri = new URI(
        "sip",
        config.impu,
        config.domain,
        null,
        null,
        null
      );

      this._registrationTimer = null;

      // Ongoing Register request.
      this._registering = false;

      // Set status.
      this._registered = false;

      // Contact header.
      if (config.tcp) {
        this._contact = `<${config.contact_uri};transport=TCP>`;
      } else {
        this._contact = `<${config.contact_uri}>`;
      }

      // Sip.ice media feature tag (RFC 5768).
      //this._contact += ";+sip.ice";

      this._contact +=
        ';+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";+g.3gpp.mcptt';

      // Custom headers for REGISTER and un-REGISTER.
      this._extraHeaders = [];

      // Custom Contact header params for REGISTER and un-REGISTER.
      this._extraContactParams = "";

      this._NumRegistrationFailures = 0;

      this.body = "";

      if (reg_id) {
        //this._contact += `;reg-id=${reg_id}`;
        //this._contact += `;+sip.instance="<urn:uuid:${this._ua.configuration.instance_id}>"`;
      }

      this.domain = config.domain;

      this.nonce = "";
      this.response = "";
      this.username = config.authorization_user; //`${config.impu}@${config.domain}`;
      this.sipUri = `sip:${this.domain}`;
    }

    get registered() {
      return this._registered;
    }

    setExtraHeaders(extraHeaders) {
      if (!Array.isArray(extraHeaders)) {
        extraHeaders = [];
      }
      this._extraHeaders = extraHeaders.slice();
    }

    setExtraContactParams(extraContactParams) {
      if (!(extraContactParams instanceof Object)) {
        extraContactParams = {};
      }

      // Reset it.
      this._extraContactParams = "";

      for (const param_key in extraContactParams) {
        if (
          Object.prototype.hasOwnProperty.call(extraContactParams, param_key)
        ) {
          const param_value = extraContactParams[param_key];

          this._extraContactParams += `;${param_key}`;
          if (param_value) {
            this._extraContactParams += `=${param_value}`;
          }
        }
      }
    }

    updateBody(body) {
      this.body = body;
    }

    deRegister(isRetry = false) {
      const _this = this;
      console.log("deRegister()", isRetry);

      return new Promise(function (resolve, reject) {
        const extraHeaders = _this._extraHeaders.slice();
        extraHeaders.push(`Contact: ${_this._contact}`);
        extraHeaders.push(`Expires: ${0}`);

        const request = new OutgoingRequest(
          JsSIP_C.REGISTER,
          _this.sipUri,
          _this._ua,
          {
            to_uri: _this._from_uri,
            from_uri: _this._from_uri,
            call_id: _this._call_id,
            cseq: (_this._cseq += 1),
          },
          extraHeaders,
          _this.body
        );
        const request_sender = new RequestSender(_this._ua, request, {
          onRequestTimeout: () => {
            console.log(
              "this._registering deregistered response error timeout "
            );
            _this._ua.sendLogsToApp("deregister", {
              "reason": "deregistered response error timeout",
              "retry": isRetry
            });
            if (isRetry) {
              setTimeout(() => {
                if (_this._registering == false) {
                  _this
                    .deRegister()
                    .then((res) => {
                      resolve(true);
                    })
                    .catch((err) => {
                      console.log("deregsiter timeout error catch..", err);
                    });
                }
              }, 2000);
            } else {
              resolve(true);
            }
          },
          onTransportError: () => {
            console.log(
              "this._registering deregistered response error onTransportError "
            );
            _this._ua.sendLogsToApp("deregister", {
              "reason": "deregistered response transportError",
              "retry": isRetry
            });
            if (isRetry) {
              setTimeout(() => {
                if (_this._registering == false) {
                  _this
                    .deRegister()
                    .then((res) => {
                      resolve(true);
                    })
                    .catch((err) => {
                      console.log("deregsiter onTransportError catch..", err);
                    });
                }
              }, 2000);
            } else {
              resolve(true);
            }
          },
          // Increase the CSeq on authentication.
          onAuthenticated: () => {
            _this._cseq += 1;
          },
          onReceiveResponse: (response) => {
            //case handle for 503 service unavailbe
            console.log("this._registering deregistered response...");
            _this._ua.sendLogsToApp("deregister", {
              "reason": "deregistered response success",
              "retry": isRetry
            });
            _this._registering = false;
            resolve(true);
          },
        });
        request_sender.send();
      });
    }

    register(body, forcefulCall = false, callback = null) {
      //debug("Register request JSSIP ...");
      console.log("register() request started..", this._expires);

      // if (this.nonce.length === 0) {
      //   this.authorizeBeforeRegister(body, callback);
      //   return;
      // }

      if (this._registering && !forcefulCall) {
        console.log(
          "this._registering registered in forcefulCall block..",
          this._registering
        );
        this._ua.sendLogsToApp("register", {
          "reason": "Register request is in progress",
          "registering": this._registering
        });
        return;
      }
      this.body = body;
      const extraHeaders = this._extraHeaders.slice();
      extraHeaders.push(`Contact: ${this._contact}`);
      extraHeaders.push(`Expires: ${this._expires}`);
      const _this = this;
      const request = new OutgoingRequest(
        JsSIP_C.REGISTER,
        this.sipUri,
        this._ua,
        {
          to_uri: this._from_uri,
          from_uri: this._from_uri,
          call_id: this._call_id,
          cseq: (this._cseq += 1),
        },
        extraHeaders,
        this.body
      );
      //console.log('this._registering Body is:', body)
      const request_sender = new RequestSender(this._ua, request, {
        onRequestTimeout: () => {
          console.log("Registration response timeout");
          _this._ua.sendLogsToApp("register", {
            "reason": "registered response timeout"
          });
          _this._registrationFailure(null, JsSIP_C.causes.REQUEST_TIMEOUT);
        },
        onTransportError: () => {
          console.log(
            "Registration transport error "
          );
          _this._ua.sendLogsToApp("register", {
            "reason": "registered response error onTransportError"
          });
          _this._registrationFailure(null, JsSIP_C.causes.CONNECTION_ERROR);
        },
        // Increase the CSeq on authentication.
        onAuthenticated: () => {
          _this._cseq += 1;
        },
        onReceiveResponse: (response) => {
          console.log("this._registering registered response.....", response.status_code);
          _this.handleRegistrationResponse(response, callback);
        },
      });

      this._registering = true;
      request_sender.send();
    }

    handleRegistrationResponse(response, callback) {
      console.log(
        "handleRegistrationResponse called....",
        response.status_code,
        this._cseq,
        response.cseq,
        response.reason_phrase
      );
      this._registering = false;
      this._NumRegistrationFailures = 0;

      // Discard responses to older REGISTER/un-REGISTER requests.
      if (response.cseq !== this._cseq) {
        console.log("discard reg response beacuse of cseq mismatched ", response.cseq, this._cseq);
        return;
      }

      switch (true) {
        case /^1[0-9]{2}$/.test(response.status_code): {
          // Ignore provisional responses.
          break;
        }

        case /^2[0-9]{2}$/.test(response.status_code): {
          if (!response.hasHeader("Contact")) {
            debug(
              "no Contact header in response to REGISTER, response ignored"
            );
            break;
          }

          const contacts = response.headers["Contact"].reduce(
            (a, b) => a.concat(b.parsed),
            []
          );

          // Get the Contact pointing to us and update the expires value accordingly.
          const contact = contacts.find(
            (element) => element.uri.user === this._ua.contact.uri.user
          );

          if (!contact) {
            debug("no Contact header pointing to us, response ignored");
            break;
          }

          let expires = contact.getParam("expires");
          //console.log('handleRegistrationResponse expires contact....', expires)
          if (!expires && response.hasHeader("expires")) {
            expires = response.getHeader("expires");
          }

          if (!expires) {
            expires = this._expires;
          }

          expires = Number(expires);
          console.log(
            "handleRegistrationResponse expires value ",
            expires
          );

          let timeout = expires;
          if (timeout > MAX_REGISTER_EXPIRES) { //60
            timeout = MAX_REGISTER_EXPIRES - deltaTimeoutVal;
          } else if (timeout < MIN_REGISTER_EXPIRES) {
            timeout = MIN_REGISTER_EXPIRES;
          } else {
            timeout = timeout - deltaTimeoutVal;
            if (timeout < MIN_REGISTER_EXPIRES) {
              timeout = MIN_REGISTER_EXPIRES;
            }
          }
          timeout = timeout * 1000;

          // Save gruu values.
          if (contact.hasParam("temp-gruu")) {
            this._ua.contact.temp_gruu = contact
              .getParam("temp-gruu")
              .replace(/"/g, "");
          }
          if (contact.hasParam("pub-gruu")) {
            this._ua.contact.pub_gruu = contact
              .getParam("pub-gruu")
              .replace(/"/g, "");
          }
          console.log(
            "registration response 200 with _registered status...",
            this._registered
          );
          this._registered = true;
          this._ua.registered({ regTimerVal: timeout });
          if (this._ua.getUserProfileRole() == 'user') {
            this._ua.checkPreEstSessionInRegInterval();
          } else {
            console.log("getUserProfileRole()...", this._ua.getUserProfileRole());
          }
          break;
        }

        // Interval too brief RFC3261 10.2.8.
        case /^4[0-9]{2}$/.test(response.status_code): {
          console.log(
            "this._registering response.status_code 400 series ",
            response.status_code
          );

          if (response.status_code == 410) {
            this._registered = false;
            this._registering = false;
            this._ua.sendLogsToApp("register", {
              "reason": "going to call relogin after getting 410 response"
            });
            this._ua.relogin();
            return;
          } else if (response.status_code == 480) {
            this._registering = false;
            this._registered = false;
            this._ua.sendLogsToApp("register", {
              "reason": "going to call _loginFailed after getting 480 response"
            });
            let reason = response.reason_phrase ? response.reason_phrase : "MCX:register: server reject with res code " +
              response.status_code;
            this._ua._loginFailed(reason);
            return;
          } else {
            // This response MUST contain a Min-Expires header field.
            this._ua.sendLogsToApp("registerFailure", {
              "reason": "registerFailure SIP_FAILURE_CODE",
              "code": response.status_code
            });
            console.log("response code error..", response.status_code, this._registered);
            // this._registered = false;
            // this._registering = false;
            // let reason = response.reason_phrase ? response.reason_phrase : "MCX:register: server reject with res code " +
            //   response.status_code;
            // this._ua._loginFailed(reason);
            this._registrationFailure(null, JsSIP_C.causes.CONNECTION_ERROR);
          }
          break;
        }

        case /^5[0-9]{2}$/.test(response.status_code): {
          console.log("re-registration after getting 500 service code");
          this._registrationFailure(null, JsSIP_C.causes.CONNECTION_ERROR);
          break;
        }

        default: {
          console.log("re-registration after getting default");
          this._registrationFailure(null, JsSIP_C.causes.CONNECTION_ERROR);
          break;
        }
      }
    }

    /*startRegistrationTimer(timeout) {
      // Re-Register or emit an event before the expiration interval has elapsed.
      // For that, decrease the expires value. ie: 3 seconds.
      console.log("added registration timer ", timeout);
      const _this = this;
      this._registrationTimer = setTimeout(() => {
        console.log("registration timer... 5", timeout);
        _this._registrationTimer = null;
        // If there are no listeners for registrationExpiring, renew registration.
        // If there are listeners, let the function listening do the register call.
        if (_this._ua.listeners("registrationExpiring").length === 0) {
          console.log("registration timer... 6");
          _this.register(_this.body);
        } else {
          _this._ua.emit("registrationExpiring");
        }
      }, timeout);
    }*/

    close() {
    }

    onTransportClosed() {
      this._registering = false;
      console.log('onTransportDisconnect method called index');
      if (this._registered) {
        console.log('onTransportDisconnect method called inside');
        this._registered = false;
        this._ua.unregistered({
          response: null,
          cause: JsSIP_C.causes.CONNECTION_ERROR,
        });
      }
    }

    _registrationFailure(response, cause) {
      console.log("_registrationFailure......", this._registered, cause);
      this._registering = false;
      this._registered = false;
      this._ua.unregistered({
        response: response || null,
        cause,
      });

      /*this._ua.registrationFailed({
        response: response || null,
        cause,
      });
      if (this._registered) {
        this._registered = false;
        this._ua.unregistered({
          response: response || null,
          cause,
        });
      }*/
    }

    _unregistered(response, cause) {
      console.log("_unregistered called......", cause);
      this._registering = false;
      this._registered = false;
      this._ua.unregistered({
        response: response || null,
        cause: cause || null,
      });
    }

    authorizeBeforeRegister(body, callback) {
      console.log("Authorized started...", body);
      this.body = body;
      const extraHeaders = this._extraHeaders.slice();
      extraHeaders.push(
        `Authorization: Digest username="${this.username}", realm="${this._ua.config.realm}", nonce="", uri="${this.sipUri}", response="", algorithm=MD5`
      );
      extraHeaders.push(`Contact: ${this._contact}`);
      extraHeaders.push(`Expires: ${this._expires}`);
      extraHeaders.push(`P-Preferred-Identity: <${this._from_uri}>`);

      const request = new OutgoingRequest(
        JsSIP_C.REGISTER,
        this.sipUri,
        this._ua,
        {
          to_uri: this._from_uri,
          from_uri: this._from_uri,
          call_id: this._call_id,
          cseq: (this._cseq += 1),
        },
        extraHeaders,
        this.body
      );
      //console.log('Body is:', body)
      const request_sender = new RequestSender(this._ua, request, {
        onRequestTimeout: () => {
          console.log("authorized response timeout");
        },
        onTransportError: () => {
          console.log("authorized response error");
        },
        // Increase the CSeq on authentication.
        onAuthenticated: (req) => {
          console.log("authorization onAuthenticated...", req);
          this._cseq += 1;
          // req.send();
        },
        onReceiveResponse: (response) => {
          console.log("authorization response...", response);
          if (response.status_code == 200) {
            //MCPTT Registration
            let contentTypeMcptt = "application/vnd.3gpp.mcptt-info+xml";
            let pService = "urn:urn-7:3gpp-service.ims.icsi.mcptt";
            let acceptContent =
              '*;+g.3gpp.mcptt;require;explicit;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcptt";require;explicit';

            this.publishPOCSettings(
              body,
              this._contact,
              contentTypeMcptt,
              pService,
              acceptContent,
              callback
            );
          }
        },
      });
      request_sender.send();
    }

    mcdataRegistrationThroughPOC(callback = null) {
      let dataContactHeader = `<${this._ua.config.contact_uri}>`;
      if (this._ua.config.tcp) {
        dataContactHeader = `<${this._ua.config.contact_uri};transport=TCP>`;
      }
      //MCDATA Registration
      dataContactHeader +=
        ';+g.3gpp.mcdata;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcdata";+g.3gpp.mcdata.sds="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcdata.sds"';
      let dataBody = this.createMcpDataInfoXml();
      let contentTypeMcdata = "application/vnd.3gpp.mcdata-info+xml";
      let pServiceData = "urn:urn-7:3gpp-service.ims.icsi.mcdata";
      let acceptContentData =
        '*;+g.3gpp.mcdata;require;explicit;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcdata";require;explicit';
      this.publishPOCSettings(
        dataBody,
        dataContactHeader,
        contentTypeMcdata,
        pServiceData,
        acceptContentData,
        callback
      );
    }

    mcVideoRegistrationThroughPOC(callback = null) {
      let videoContactHeader = `<${this._ua.config.contact_uri}>`;
      if (this._ua.config.tcp) {
        videoContactHeader = `<${this._ua.config.contact_uri};transport=TCP>`;
      }
      //MCVIDEO Registration
      videoContactHeader +=
        '+g.3gpp.mcvideo;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcvideo"';
      let videoBody = this.createMcpVideoInfoXml();
      let contentTypeMcvideo = "application/vnd.3gpp.mcvideo-info+xml";
      let pServiceVideo = "urn:urn-7:3gpp-service.ims.icsi.mcvideo";
      let acceptContentVideo =
        '*;+g.3gpp.mcvideo;require;explicit;+g.3gpp.icsi-ref="urn%3Aurn-7%3A3gpp-service.ims.icsi.mcvideo";require;explicit';
      this.publishPOCSettings(
        videoBody,
        videoContactHeader,
        contentTypeMcvideo,
        pServiceVideo,
        acceptContentVideo,
        callback
      );
    }

    publishPOCSettings(
      regBody,
      contHeader,
      contentType,
      pService,
      acceptContent,
      callback = null
    ) {
      const _this = this;
      const extraHeaders = [
        `Contact: ${contHeader}`,
        `Expires: ${"4294967295"}`,
        "Event: poc-settings",
        "Content-Type: multipart/mixed; boundary=boundary",
        `P-Preferred-Identity: ${this._from_uri}`,
        `P-Preferred-Service: ${pService}`,
        `Accept-Contact: ${acceptContent}`,
      ];
      let pocBody = this.getPOCBody(regBody, contentType);
      const request = new OutgoingRequest(
        "PUBLISH",
        this._registrar,
        this._ua,
        {
          to_uri: this._registrar,
          from_uri: this._from_uri,
          call_id: Utils.createRandomToken(22),
          cseq: (this._cseq += 1),
        },
        extraHeaders,
        pocBody
      );

      const eventHandlers = {
        onReceiveResponse: (response) => {
          console.log("this._registering registered response poc...");
          _this._registered = true;
        },
        onRequestTimeout: () => {
          console.log("webRTC.sendPublishRequest.onRequestTimeout");
        },
        onTransportError: () => {
          console.log("webRTC.sendPublishRequest.onTransportError");
          if (callback) {
            //callback('transport error')
          }
        },
        onAuthenticated: () => {
          console.log("webRTC.sendPublishRequest.onAuthenticated");
          if (callback) {
            callback("authentication failed");
          }
        },
      };
      const requestSender = new RequestSender(this._ua, request, eventHandlers);
      requestSender.send();
    }

    createPOCSetting() {
      return `<?xml version="1.0" encoding="UTF-8"?>
              <poc-settings xmlns="urn:oma:params:xml:ns:poc:poc-settings" xmlns:mcs10Set="urn:3gpp:mcsSettings:1.0">
                  <entity id='${this.instance_id}'>
                      <am-settings>
                          <answer-mode>manual</answer-mode>
                      </am-settings>
                      <mcs10Set:selected-user-profile-index>
                          <mcs10Set:user-profile-index>0</mcs10Set:user-profile-index>
                      </mcs10Set:selected-user-profile-index>
                  </entity>
              </poc-settings>`;
    }

    getPOCBody(regBody, contentType) {
      const pocSettings = `${this.createPOCSetting()}`;
      const body = `--boundary\r\nContent-Type: application/poc-settings+xml\r\n\r\n${pocSettings}\r\n\r\n--boundary\r\nContent-Type: ${contentType}\r\n\r\n${regBody}\r\n--boundary--`;
      return body;
    }

    createMcpDataInfoXml() {
      var infoData = mcdataInfo;
      infoData["mcdata-Params"]["mcdata-access-token"]["@"].type = "Normal";
      infoData["mcdata-Params"][
        "mcdata-access-token"
      ].mcdataString = this._ua._currentToken;
      infoData["mcdata-Params"]["mcdata-client-id"]["@"].type = "Normal";
      infoData["mcdata-Params"][
        "mcdata-client-id"
      ].mcdataString = this.instance_id;
      return js2xmlparser.parse("mcdatainfo", infoData);
    }

    createMcpVideoInfoXml() {
      var infoData = mcvideoInfo;
      infoData["mcvideo-Params"]["mcvideo-access-token"]["@"].type = "Normal";
      infoData["mcvideo-Params"][
        "mcvideo-access-token"
      ].mcvideoString = this._ua._currentToken;
      infoData["mcvideo-Params"]["mcvideo-client-id"]["@"].type = "Normal";
      infoData["mcvideo-Params"][
        "mcvideo-client-id"
      ].mcvideoString = this.instance_id;
      return js2xmlparser.parse("mcvideoinfo", infoData);
    }
  }
  const mcdataInfo = {
    "@": {
      xmlns: "urn:3gpp:ns:mcdataInfo:1.0",
    },
    "mcdata-Params": {
      "mcdata-access-token": {
        "@": {
          type: "",
        },
        mcdataString: "",
      },
      "mcdata-client-id": {
        "@": {
          type: "",
        },
        mcdataString: "",
      },
    },
  };

  const mcvideoInfo = {
    "@": {
      xmlns: "urn:3gpp:ns:mcvideoInfo:1.0",
    },
    "mcvideo-Params": {
      "mcvideo-access-token": {
        "@": {
          type: "",
        },
        mcvideoString: "",
      },
      "mcvideo-client-id": {
        "@": {
          type: "",
        },
        mcvideoString: "",
      },
    },
  };

  exports.Registrator = Registrator;
})(typeof exports === "undefined" ? (this["Registrator"] = {}) : exports);
