import { DataGrid } from '@mui/x-data-grid';
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const MyCatalog = (props) => {
    const { products, catalogCallBack, deviceTypeList, paginationFunc } = props;

    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDownId, setShowDropDownId] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })

    useEffect(() => {
        paginationFunc(paginationModel, "catalog")
    }, [paginationModel])

    const menuOptions = [
        { text: 'Partner', value: 1 },
        { text: 'Operator', value: 2 }
    ]

    const DropdownMenu = (val) => {
        const filteredMenuOptions = val.productSubType === 'Partner'
            ? menuOptions.filter(item => item.value === 1)
            : menuOptions;
        return (
            <div style={{ backgroundColor: '#fff', width: '150px', position: 'absolute', right: '0px' }}>
                <ul className="list-unstyled mb-0">
                    {filteredMenuOptions.map((item, id) => {
                        return (
                            <li className="media dropdown-item" key={id} onClick={() => { menuOptionsClicked(val, item.text) }}>
                                <div className="profile-icon" style={{ color: '#000' }}>
                                    {item.text}
                                </div>
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        );
    }

    const menuOptionsClicked = (val, item) => {
        if (val.productSubType == "Operator") {
            let obj = { ...val };
            if (item === "Partner") {
                obj.productSubType = "Partner"
                obj.price = 0;
            }
            else {
                obj.productSubType = "Operator";
            }
            if (catalogCallBack) {
                catalogCallBack(obj, true);
            }
        } else {
            if (catalogCallBack) {
                catalogCallBack(val, true);
            }
        }
    }

    const columns = [
        {
            field: 'productSku', headerName: 'ProductSku', width: 300, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left"
        },
        {
            field: 'productId', headerName: 'Name', width: 450, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${getProductName(row)}`,
        },
        {
            field: 'productType', headerName: 'Type', width: 150, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "left", headerAlign: "left",
        },
        {
            field: 'productSubType', headerName: 'SubType', width: 200, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "left", headerAlign: "left",
            valueGetter: (value, row) => `${getProductSubTypeName(row.productType, row.productSubType)}`,
        },
        {
            field: 'productBrand',
            headerName: 'Brand',
            width: 200,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'priceM',
            headerName: 'Price',
            width: 250,
            sortable: false,
            headerClassName: "list-head-Val",
            cellClassName: "list-row-Val",
            align: "left",
            headerAlign: "left",
            valueGetter: (value, row) => `${getPriceVal(row)}`,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 160,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValue) => {
                return (
                    <button
                        className="editBtn"
                        onClick={() => {
                            editButtonClick(cellValue.row)
                        }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/editimg.svg"
                            className="edit-user-img"
                            alt=""
                        />
                    </button>
                )
            }
        },
        {
            field: 'menu',
            headerName: 'Menu',
            width: 160,
            height: 50,
            sortable: false,
            headerClassName: "list-head-Val",
            align: "left",
            headerAlign: "left",
            renderCell: (cellValue) => {
                return (
                    <button
                        className="editBtn"
                        onClick={() => {
                            menuBtnClick(cellValue.row)
                        }}
                        type="button"
                        name=""
                    >
                        <img
                            src="/assets/images/users/drop-down-white.png"
                            className="edit-user-img"
                            alt=""
                        />
                        {showDropDown && cellValue.row.id === showDropDownId && DropdownMenu(cellValue.row)}
                    </button>
                )
            }
        },
    ];

    const menuBtnClick = (val) => {
        setShowDropDown(!showDropDown);
        setShowDropDownId(val.id);
    }

    const editButtonClick = (val) => {
        if (catalogCallBack) {
            catalogCallBack(val);
        }
    }

    const getProductName = (product) => {
        let productName = "";
        if (product && product.productJson) {
            let productJson = product.productJson;
            if (productJson && productJson.name) {
                productName = productJson.name;
            } else {
                if (product.shortDescription) {
                    productName = product.shortDescription;
                }
            }
        }
        return productName;
    }

    const getPriceVal = (product) => {
        let price = product.price;
        let priceUnit = product.priceUnit;
        return price + " /" + priceUnit;
    }

    const getProductSubTypeName = (productType, productSubType) => {
        if (productType === "MobileDevice") {
            if (deviceTypeList && deviceTypeList.length > 0) {
                let devices = deviceTypeList.filter(device => device.id == productSubType);
                if (devices && devices.length > 0) {
                    return devices[0].brand + " " + devices[0].manufacturer
                }
            }

        }
        return productSubType;
    }

    const onFilterChange = React.useCallback((filterModel) => {
        console.log("onFilterChange ", filterModel);
    }, []);

    return (
        <div style={{ height: 900 }} >
            <DataGrid
                rows={products.data ? products.data : []}
                columns={columns}
                //filterMode="server"
                onFilterModelChange={onFilterChange}
                // loading={isLoading}
                rowHeight={68}
                // hideFooter
                rowCount={products.totalItems}
                pagination
                pageSizeOptions={[10, 20, 25]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                localeText={{ noRowsLabel: "" }}
                // disableColumnMenu
                paginationMode="server"
                getRowSpacing={params => ({
                    top: params.isFirstVisible ? 0 : 0,
                    bottom: params.isLastVisible ? 0 : 0
                })}

                sx={{
                    boxShadow: 2,
                    border: "none",
                    borderBottom: "none",
                    // display: "block",
                    '& .MuiDataGrid-cell:hover': {
                        color: '#ffb01f',
                    },
                    '& .MuiDataGrid': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderColor: "#404040",
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderColor: "gray",
                    },
                    "& .MuiDataGrid-sortIcon": {
                        opacity: 1,
                        color: "white",
                    },
                    "& .MuiDataGrid-menuIconButton": {
                        opacity: 1,
                        color: "white"
                    },
                    // "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                    //     color: "white"
                    // },
                    "& .MuiTablePagination-root": {
                   color: "white", 
                   },
                   "& .MuiTablePagination-selectLabel": {
                        color: "white"
                    },
                    "& svg:not(:root)": {
                        color: 'white'
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                        color: 'white'
                    },
                    '& .MuiDataGrid-filler': {
                        backgroundColor: "#262626",
                    },
                }}
            />
        </div >
    )
}
const mapStateToProps = ({ mdm }) => {
    const { deviceTypeList } = mdm;
    return {
        deviceTypeList,
    };
};

export default connect(mapStateToProps, null)(MyCatalog);
