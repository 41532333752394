import {
    SET_APP_LIST,
    SET_CURRENT_TAB,
    SET_DEVICE_LIST,
    SET_CONFIG_LIST,
    SET_TENANT_ID,
    REFRESH_AUTH_TOKEN,
    SET_CONFIG_APPLICATION,
    SET_TENANT_LIST,
    SET_DEVICE_ID_LIST,
    SET_DEVICE_ID,
    SET_DEVICE_TYPE_LIST,
    SET_APP_CONFIG_LIST,
    SET_DEVICE_INVENTORY,
    SET_SEARCHED_TEXT,
    SET_FILTERED_DATA,
    UPDATE_CONFIG_DEVICE,
    SET_DEVICE_DASHBOARD,
    SET_DEVICE_INFO,
    SET_FINDMYDEVICE_EVENT,
    
    
} from "../actions/type";
let initialState = {
    currentTab: "Device",
    deviceList: [],
    deviceTypeList: [],
    appList: [],
    appConfigList: [],
    configList: [],
    deviceInventoryList: [],
    tenantId: "",
    configApplicationList: [],
    tenantList: [],
    deviceIdOption: [],
    deviceId: "",
    searchedText: "",
    filteredData: [],
    configDevices: [],
    deviceDashBoard:{},
    deviceInfo:{},
    findMyDeviceEvent:{},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_TAB:
            return { ...state, currentTab: action.data }
        case SET_DEVICE_LIST:
            return { ...state, deviceList: [...action.data] }
        case SET_DEVICE_TYPE_LIST:
            return { ...state, deviceTypeList: [...action.data] }
        case SET_APP_LIST:
            return { ...state, appList: [...action.data] }
        case SET_APP_CONFIG_LIST:
            return { ...state, appConfigList: [...action.data] }
        case SET_CONFIG_LIST:
            return { ...state, configList: [...action.data] }
        case SET_DEVICE_INVENTORY:
            return { ...state, deviceInventoryList: [...action.data] }
        case SET_TENANT_ID:
            return { ...state, tenantId: action.data }
        case REFRESH_AUTH_TOKEN:
            return { ...state, token: action.data }
        case SET_CONFIG_APPLICATION:
            return { ...state, configApplicationList: action.data }
        case SET_TENANT_LIST:
            let tenant = action.data && action.data.map((tenant) => {
                return { name: tenant.name, value: tenant.tenantId }
            })
            return { ...state, tenantList: tenant }
        case SET_DEVICE_ID_LIST:
            return { ...state, deviceIdOption: action.data }
        case SET_DEVICE_ID:
            console.log("actionddata", action.data);
            if (action.data)
                return { ...state, deviceId: action.data }
            return { ...state, deviceId: "" }
        case SET_SEARCHED_TEXT:
            return { ...state, searchedText: action.data }
        case SET_FILTERED_DATA:
            return { ...state, filteredData: action.data }
        case UPDATE_CONFIG_DEVICE:
            return { ...state, configDevices: action.data }
        case SET_DEVICE_DASHBOARD:
            return {...state,deviceDashBoard:action.data}
        case SET_DEVICE_INFO:
            return {...state,deviceInfo:action.data}
        case SET_FINDMYDEVICE_EVENT:
            return {...state,findMyDeviceEvent:action.data}
        default:
            return state;
    }
}