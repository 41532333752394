import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material'
import { fetchDeviceInfo } from "../../modules/actions/mdmAction";
import './style.css'
 
const DeviceInfo = (props)=>{
    
  const {switchWindow ,selectedDevicerow,deviceInfo,tenantId,fetchDeviceInfo,tenantList} = props;
  useEffect(() => {
    
   fetchDeviceInfo({ deviceId:selectedDevicerow.deviceId })
}, [])
    const handleclick=()=>{
        switchWindow();
    }
    const getTenantname=(tenantId)=>{
        if(tenantId===null)return "--";
        var filtered = tenantList.filter(function(item) {
            return (item.value == tenantId)
          });
          if(filtered.length===0)return tenantId;
          return filtered[0].name;
    }
    const getDeviceType = (device) => {
        let deviceType = "";
        if (device && device.manufacturer && device.brand) {
            deviceType = device.manufacturer + " " + device.brand
        }

        return deviceType
    }
    
    const Devicedata={
        "Model": "model",
        "Device Id": "deviceId",
        "Serial No": "serial",
        "Android Version": "androidVersion",
         "Battery Level": "batteryLevel",
        "CPU Architecture": "cpu",
         "Default Launcher": "defaultLauncher",
         "IMEI": "imei",
        "IMEI2": "imei2",
         "kiosk Mode": "kioskMode" ,
         "Launcher Package": "launcherPackage",
         "Launcher Type": "launcherType",
       //  "Location": "mdmMode",
            "Mdm Mode": "mdmMode" ,
            // "Permissions": "[1,1,1,1]",
           "Phone": "phone",
            // "Files": "[]",      
 
    }
    let permissionsArray=[];
    if ( deviceInfo && deviceInfo.permissions) {
         permissionsArray = JSON.parse(deviceInfo.permissions);
       
    } 
   
        
    return(
    <>

                 <Box
                        sx={{
                            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                            borderRadius: '12px',
                            padding: '8px',
                            overflowWrap: "break-word",
                            paddingLeft: '2%',
                            backgroundColor: 'rgba(255,255,255,0.07)',
                            marginTop:'2px',
                            color:'white',
                           
                        }}
                    >   
                   
                                <Grid container spacing={2} alignItems="center" >
                                    <Grid item>
                                  <i class="fa fa-arrow-left"  style={{cursor:"pointer"}} onClick={handleclick}></i>
                                    </Grid>
                                    <Grid item>
                                    <i className={"feather icon-smartphone f-32 my-2"} />
                                    </Grid>
                                        <Grid item>
                                        <Typography  variant="h6" className='color-white-info'>{selectedDevicerow.deviceId}</Typography>
                                        </Grid>
                                </Grid>       
                    </Box>
                    <Box
                        sx={{
                            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                            borderRadius: '12px',
                            padding: '16px',
                            overflowWrap: "break-word",
                            paddingLeft: '2%',
                            backgroundColor: 'rgba(255,255,255,0.07)',
                            marginTop:'5px',
                            color:'white'
                        }}
                    >
                         <Typography variant="h6" className='color-white-info'>Device Information</Typography>
                           { deviceInfo && <Grid container direction="row"
                            spacing={2}
                            columns={12}
                            style={{ marginTop:"16px",marginBottom:"16px"}}
                            >  
                            <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>User Id</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.userId?selectedDevicerow.userId:"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid>  
                            {Object.entries(Devicedata).map(([key, value]) => { 
                               
                                           
                               return (
                                  
                               <>
                               
                               <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>{key} </span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{deviceInfo[value]?deviceInfo[value]:"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </>
                        );
                    })}     
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Permission to install as device administrator</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{deviceInfo.permissions &&  (permissionsArray.length>=2) && (permissionsArray[0]=="1") ? "Yes" : deviceInfo.permissions &&  (permissionsArray.length>=2) && (permissionsArray[0]=="0") ?"No":"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                         
                         
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Permission to overlay on top of other windows</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{deviceInfo.permissions &&  (permissionsArray.length>=2) && (permissionsArray[1]=="1") ? "Yes" : deviceInfo.permissions &&  (permissionsArray.length>=2) && (permissionsArray[1]=="0") ?"No":"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Cms Uri</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.cmsURI?selectedDevicerow.cmsURI:"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Allocation Status</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.deviceState?selectedDevicerow.deviceState:"Not Assigned"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Tenant Id</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.tenantId?getTenantname(selectedDevicerow.tenantId):"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Configuration</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.name?selectedDevicerow.name:"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  <Grid item xs={6} >
                                      <Grid container item spacing={2}>
                                          <Grid item xs={6}>
                                              <span className='header-name-info'>Device Type</span>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <span className='content-style-info'>{selectedDevicerow.deviceType?getDeviceType(selectedDevicerow):"--"}</span>
                                          </Grid>
                                      </Grid>
                                  </Grid> 
                                  
                                  
                                  
             
              
                               
                 </Grid>}
                 
                    </Box>
                    {
                        deviceInfo &&  deviceInfo.applications  && Array.isArray(deviceInfo.applications) && (
                            <Box
                        sx={{
                            // boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
                            borderRadius: '12px',
                            padding: '16px',
                            overflowWrap: "break-word",
                            paddingLeft: '2%',
                            backgroundColor: 'rgba(255,255,255,0.07)',
                            marginTop:'5px',
                            color:'white'
                        }}
                    >
                           
                 <div className='w-100'>
           
                <Typography variant="h6" className='color-white-info'>Installed Application</Typography>
          
            <div className="appTable-row-grid-head">
                
                <div class="app-tb-appname">
                    <span>Application Name</span>
                </div>
                <div class="app-tb-package">
                    <span>Package Id</span>
                </div>
                <div class="app-tb-version">
                    <span>Version</span>
                </div>
                
            </div>
            <div style={{ height: "16vh", overflowY: "scroll"}}>
                 {deviceInfo &&  deviceInfo.applications  && Array.isArray(deviceInfo.applications) && deviceInfo.applications.map((data,idx) => {
                    return (
                        <div className="appTable-row-grid" >
                <React.Fragment >
            
            <div class="app-tb-appname">
                <span>{data.name}</span>
            </div>
            <div class="app-tb-package">
                <span>{data.pkg }</span>
            </div>
           
            <div class="app-tb-version">
                <span>{data.version}</span>
            </div>
            
        </React.Fragment>
                </div>

                    );
                }
                )} 
       
            </div>
        </div>
                    </Box>
                        )
                    }
                    </>
    );

}

const mapStateToProps = ({ mdm }) => {
    return {
        tenantId: mdm.tenantId,
        deviceInfo: mdm.deviceInfo,
        tenantList:mdm.tenantList,
    }
}
export default connect(mapStateToProps, { fetchDeviceInfo })(DeviceInfo)

