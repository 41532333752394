import React, { useState, useEffect } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import {
    getCallRecord,
    setPage,
    setPaginationModel,
    // setTotalPage, setHasMore, setLoader, setShowingNextData 
} from "../../modules/actions/playerAction";
import SdsTableRow from './SdsTableRow';
import { Modal as ModalBootStrap } from "react-bootstrap";
import SdsRecordInfoTab from './SdsRecordInfoTab';
import { Title } from '../commom';
import ArchiveRecordings from './ArchiveRecordings';
import { DataGrid } from "@mui/x-data-grid";
import { showTime } from './definedFunction';
import { otherStatus, SdsStatus, paAlerts } from '../../constants/constants';

const SdsTable = (props) => {
    const { callRecord, pagination, callRecordReqObj, paginationModel, setPaginationModel } = props;

    const [isSearch, setSearched] = useState(false);
    const [filtered, setFiltered] = useState();
    const [lastfiltered, setLastFiltered] = useState();
    const [isSdsInfoModalOpen, setIsSdsInfoModalOpen] = useState(false);
    const [isSdsRecordSelected, setSdsRecordSelected] = useState();

    const [currentPage, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoader] = useState(false);
    const [showingNextData, setShowingNextData] = useState(false);
    const totalPageSize = 20;
    const [localPagination, setLocalPagination] = useState(paginationModel)
    const setLocal = (paginationValue, event) => {
        setLocalPagination(paginationValue)
        setPaginationModel(paginationValue)
    }
    useEffect(() => {
        fetchPage(localPagination.page + 1, localPagination.pageSize)
    }, [localPagination])
    useEffect(() => {
        if (!isSearch) {
            setFiltered(callRecord);
            setLastFiltered(callRecord);
            setLoader(false)
            if (pagination && pagination.totalPages && pagination.currentPage) {
                setTotalPage(pagination.totalPages)
                if (pagination.totalPages != pagination.currentPage)
                    setHasMore(true);
            }

            setPage(pagination.currentPage);
            setShowingNextData(false);
        }
        console.log("call record data :", currentPage);
    }, [callRecord])

    const archiveModalhandleClose = () => {
        setIsArchiveModalOpen(false);
    };
    const handleShowArchiveButton = () => {
        setIsArchiveModalOpen(true);
    }

    const searchRecord = (searchRecord) => {
        let filterRecord;
        const search = searchRecord.toLowerCase();
        if (search) {
            filterRecord = lastfiltered.filter(record =>
                (record.callID && record.callID.toLowerCase().includes(search)) ||
                (record.calleeDisplayName && record.calleeDisplayName.toString().toLowerCase().includes(search)) ||
                (record.callerDisplayName && record.callerDisplayName.toString().toLowerCase().includes(search)) ||
                (record.displayGroupName && record.displayGroupName.toLowerCase().includes(search))
            )
            setFiltered(filterRecord);
            setSearched(true);
        }
        else {
            setFiltered(lastfiltered);
            setSearched(false);
        }
    }

    const getCallRecordList = (filterData) => {
        let params = callRecordReqObj;
        params.page = filterData.page;
        params.limit = totalPageSize;
        console.log("show button is clicked", params);
        props.getCallRecord(params);
    }

    const fetchPage = (page, pageSize) => {
        let filterData = {
            page: page,
            pageSize: pageSize
        }
        console.log('fetchPage -->', page);
        setPage(page)
        setLoader(true);
        getCallRecordList(filterData)
    }

    const getMoreData = () => {
        console.log('getMoreData scroll..', currentPage, totalPage)
        if (currentPage === totalPage) {
            setHasMore(false);
            return;
        }
        setLoader(true)
        setShowingNextData(true)
        fetchPage(currentPage + 1)
    }

    const userFilterModalhandleClose = () => {
        setIsSdsInfoModalOpen(false);
        setSdsRecordSelected(null);
    }

    const handleShowModal = (data, idx) => {
        console.log("handle show modal----", data.row);
        setIsSdsInfoModalOpen(true);
        setSdsRecordSelected(data.row);
    }

    const columns = [
        {
            field: 'calleeName', headerName: 'To', width: 243, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showCalleeName(row)}`,
        },
        {
            field: 'callerName', headerName: 'From', width: 243, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showCallerName(row)}`,
        },
        {
            field: 'groupName', headerName: 'Group', width: 243, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showGroupName(row)}`,
        },
        {
            field: 'time', headerName: 'Time', width: 243, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showTime(row.recordStartTime)}`,
        },
        {
            field: 'sessionType', headerName: 'SDS Type', width: 243, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center"
        },
        {
            field: 'callDuration', headerName: 'Message', width: 262, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${row.sdsPayloadData ? getEncodedMessage(row) : ""}`
        },
    ];


    const showCalleeName = (data) => {
        if (data) {
            if (data.calleeDisplayName) {
                return data.calleeDisplayName;
            }
        }
        return ""
    }
    const showCallerName = (data) => {
        if (data) {
            if (data.callerDisplayName) {
                return data.callerDisplayName;
            }
        }
        return ""
    }
    const showGroupName = (data) => {
        if (data) {
            if (data.displayGroupName) {
                return data.displayGroupName;
            }
        }
        return ""
    }

    const getEncodedMessage = (data) => {

        let truncateData = data.sdsPayloadData
        if (data.sdsPayloadData.length > 20) {
            truncateData = truncateData.substring(0, 19) + "...";
        }
        if (data.sessionType === 'STATUS') {
            return getAlert(truncateData)
        } else {
            return truncateData;
        }
    }
    const getAlert = (code) => {
        const alert = [...SdsStatus, ...otherStatus, ...paAlerts].filter(status => Number(status.code) === Number(code));
        if (alert && alert.length > 0) return alert[0].desc
        else return code
    }


    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={10}>
                    <Title title="SDS Recordings" type="TD" search={searchRecord} />
                </Grid>
                <Grid item xs={2} style={{ display: "flex", justifyContent: "end" }} >
                    <Button style={{ backgroundColor: "#ffb01f", color: "black", width: "80%" }} variant="container" onClick={handleShowArchiveButton}>Show Archives</Button>
                </Grid>
            </Grid>
            <div style={{ height: 900, marginTop: "5px" }}>
                <DataGrid
                    rows={filtered ? filtered : []}
                    getRowId={(row) => row.id}
                    onRowClick={handleShowModal}
                    columns={columns}
                    rowHeight={68}
                    // hideFooter
                    // hideFooterPagination
                    // hideFooterSelectedRowCount
                    disableColumnMenu
                    rowCount={pagination.rowCount || pagination.pageSize * pagination.totalPages}
                    pagination
                    pageSizeOptions={[10, 20, 25]}
                    paginationModel={localPagination}
                    onPaginationModelChange={setLocal}
                    paginationMode="server"
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        "& .MuiDataGrid-sortIcon": {
                            opacity: 1,
                            color: "white",
                        },
                        "& .MuiDataGrid-menuIconButton": {
                            opacity: 1,
                            color: "white"
                        },
                        // "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                        //     color: "white"
                        // },
                        "& .MuiTablePagination-root": {
                            color: "white",
                        },
                        "& .MuiTablePagination-selectLabel": {
                            color: "white"
                        },
                        "& svg:not(:root)": {
                            color: 'white'
                        },
                        "& .css-levciy-MuiTablePagination-displayedRows": {
                            color: 'white'
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
                {/* <div class="sds-row-grid-head">
                <div class="sds-tb-to train-cell-center ">
                    <span>To</span>
                </div>
                <div class="sds-tb-from train-cell-center ">
                    <span>From</span>
                </div>
                <div class="sds-tb-grp train-cell-center">
                    <span>Group</span>
                </div>
                <div class="sds-tb-time train-cell-center ">
                    <span>Time</span>
                </div>
                <div class="sds-tb-msgType train-cell-center ">
                    <span>SDS Type</span>
                </div>
                <div class="sds-tb-msg train-cell-center">
                    <span>Message</span>
                </div>
            </div>


            <div id="scrollableDivCallRecord" style={{ height: "686px", overflowY: "auto" }} >
                {filtered ? <InfiniteScroll
                    dataLength={filtered.length}
                    next={getMoreData}
                    hasMore={hasMore}
                    loader={loading ? <div class='al-center'><p class='white'>Loading</p></div> : <div></div>}
                    scrollableTarget='scrollableDivCallRecord'
                >
                    {filtered && filtered.map((data, idx) => {
                        return (
                            <div className="sds-row-grid" key={idx} onClick={(idx) => handleShowModal(data, idx)}>
                                <SdsTableRow data={data} />
                            </div>
                        );
                    }
                    )}
                </InfiniteScroll> : <div>
                    {filtered && filtered.length === 0 ?
                        <div
                            class="pt-6 pb-6 pr-6 pl-6 f-18 f-reg white"
                            style={{ height: '445px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >{isSearch ? 'No Record found' : ''}
                        </div>
                        : null}
                </div>}
            </div> */}
            </div>

            <ModalBootStrap
                show={isSdsInfoModalOpen}
                onHide={userFilterModalhandleClose}
                scrollable={true}
                // size={"xl"}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>SDS</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        boxShadow: 1,
                        border: 2,
                        p: 1,
                        padding: "8px",
                        margin: "2px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        backgroundColor: "#121212",
                        borderRadius: "16px"
                    }}
                >
                    {isSdsRecordSelected ? <SdsRecordInfoTab data={isSdsRecordSelected} /> : ""}
                </ModalBootStrap.Body>
            </ModalBootStrap>
            <ModalBootStrap
                show={isArchiveModalOpen}
                onHide={archiveModalhandleClose}
                scrollable={true}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                // dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>Archival Management</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        minHeight: "45vh",
                        boxShadow: 1,
                        border: 2,
                        margin: "8px",
                        backgroundColor: "#121212",
                        borderRadius: "16px",
                        padding: "0px"
                    }}
                >
                    <ArchiveRecordings />
                </ModalBootStrap.Body>
            </ModalBootStrap>
        </React.Fragment>
    )
}


const mapStateToProps = ({ player }) => {
    return {
        callRecord: player.callRecord,
        pagination: player.pagination,
        callRecordReqObj: player.callRecordReqObj,
        currentPage: player.currentPage,
        totalPage: player.totalPage,
        hasMore: player.hasMore,
        loading: player.loading,
        showingNextData: player.showingNextData,
        paginationModel: player.paginationModel
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCallRecord,
            setPage,
            setPaginationModel
            // setTotalPage, setHasMore, setLoader, setShowingNextData
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SdsTable);