(function (exports) {
  const EndPoints = require("../endpoints").EndPoints;
  const axios = require("axios").default;

  class MDM {
    constructor() {
      this.headers = {
        "Content-Type":
          'application/org.3gpp.mcptt.user-profile+xml; charset="utf-8" ',
      };
    }
    getMethod(url, token) {
      return new Promise((resolve, reject) => {
        axios.get(url, {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        .then((resp) => {
          resolve(resp)
        }).catch((err) => {
          reject(err)
        })
      })
    }
    deleteMethod(url, token) {
      return new Promise((resolve, reject) => {
        axios.delete(url, {
          headers: {
            authorization: `Bearer ${token}`
          }
        }).then((resp) => {
          resolve(resp)
        }).catch((err) => {
          reject(err)
        })
      })
    }
    putMethod(url, token, data) {
      return new Promise((resolve, reject) => {
        axios.put(url, data, {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
          .then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
      })
    }
    postMethod(url, token, data) {
      return new Promise((resolve, reject) => {
        axios.post(url, data, {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
          .then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
      })
    }
    fetchDeviceList(data) {
      return this.getMethod(`${EndPoints.getConfig().getDeviceList}?tenantId=${data.tenantId}&mtmId=${data.mtmId}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching device list", err);
          return null;
        })
    }
    fetchDeviceTypeList(data) {
      return this.getMethod(`${EndPoints.getConfig().getDeviceTypeList}`, data)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    fetchAppList(data) {
      return this.getMethod(`${EndPoints.getConfig().getAppList}?tenantId=${data.tenantId}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching app list", err);
          return null;
        })
    }
    fetchAppConfigList(data) {
      return this.getMethod(`${EndPoints.getConfig().applicationConfigReln}?appId=${data.id}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching app list", err);
          return null;
        })
    }
    fetchConfigList(data) {
      return this.getMethod(`${EndPoints.getConfig().getConfigList}?tenantId=${data.tenantId}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching config list", err);
          return null;
        })
    }
    fetchInventoryList(data) {
      return this.getMethod(`${EndPoints.getConfig().getDeviceInventory}?deviceType=${data.deviceType}&numDevices=${data.deviceQuantity}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching Device Inventory", err);
          return null;
        })
    }
    deleteConfiguration(data) {
      return this.deleteMethod(`${EndPoints.getConfig().deleteConfigurations}?tenantId=${data.tenantId}&configId=${data.configId}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    deleteDevice(data) {
      return this.deleteMethod(`${EndPoints.getConfig().deleteDevice}?tenantId=${data.tenantId}&id=${data.id}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    deleteDeviceType(data) {
      return this.deleteMethod(`${EndPoints.getConfig().getDeviceTypeList}?id=${data.id}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    deleteApp(data) {
      return this.deleteMethod(`${EndPoints.getConfig().deleteApplication}?tenantId=${data.tenantId}&appId=${data.id}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    updateConfig(data) {
      const { token, ...bodyData } = data;
      return this.putMethod(`${EndPoints.getConfig().updateConfig}`, token, bodyData)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          return null;
        })
    }
    fetchConfigApp(data) {
      return this.getMethod(`${EndPoints.getConfig().fetchConfigApp}?configurationId=${data.id}`, data.token)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in fetching Config App Relation", err);
          return null;
        })
    }
    addConfigAppReln(data, token) {
      return this.postMethod(`${EndPoints.getConfig().fetchConfigApp}`, token, data)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in adding Config App Relation", err);
          return null;
        })
    }
    allocateDevice(data, token) {
      return this.postMethod(`${EndPoints.getConfig().allocateDevice}`, data.token, {deviceList:data.selectedCheckedBox,tenantId:data.listType.tenantType,configurationId:data.listType.configType,numDevices:data.deviceData.deviceQuantity,createConfig:data.createConfig['createConfiguration'], configurationName:data.createConfig.configurationName})
        .then((res) => {
          if (res && res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in Allocating Devices", err);
          return null;
        })
    }
    addDevice(data, token) {
      return this.postMethod(`${EndPoints.getConfig().addDevice}`, token, data)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in adding Config App Relation", err);
          return null;
        })
    }
    addDeviceType(data, token) {
      return this.postMethod(`${EndPoints.getConfig().getDeviceTypeList}`, token, data)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in adding Device Type", err);
          return null;
        })
    }
    updateDevice(data, token) {
      return this.putMethod(`${EndPoints.getConfig().addDevice}`, token, data)
        .then((res) => {
          if (res.data)
            return res.data
          return null;
        }).catch((err) => {
          console.log("Error in adding Config App Relation", err);
          return null;
        })
    }
    
    pullLogs(data){
      return this.getMethod(`${EndPoints.getConfig().pullLogs}?deviceId=${data.id}`, data.token)
    }
    getTenantId(data){
      return axios.post(`${EndPoints.getConfig().getAllSubDomains}`, {data})
    }
    getTenantList(data){
      return this.getMethod(`${EndPoints.getConfig().getTenantListMDM}?partnerId=${data.partnerId}`)
    }
    getDeviceIdList(data){
      return this.getMethod(`${EndPoints.getConfig().getDeviceIdListId}?tenantId=${data.tenantId}&ueType=${data.ueType}`,data.token)
    }
    updateDeviceByDeviceId(data,token){
      return this.putMethod(`${EndPoints.getConfig().updateDeviceByDeviceId}`, token, data)
    }
    updateDeviceByUser(data,token){
      return this.putMethod(`${EndPoints.getConfig().updateDeviceByUser}`, token, data)
    }
    getDeviceId(data){
      return this.getMethod(`${EndPoints.getConfig().getDeviceId}?tenantId=${data.tenantId}&userId=${data.userId}`, data.token)
    }
    getConfigByDevice(data){
      return this.getMethod(`${EndPoints.getConfig().getConfigDevice}?tenantId=${data.tenantId}&deviceType=${data.deviceType}`, data.token)
    }
    fetchDeviceDashboard(data){
      return this.getMethod(`${EndPoints.getConfig().deviceDashboard}?tenantId=${data.tenantId}&mtmId=${data.mtmId}`, data.token)

    }
    fetchDeviceInfo(data){
      return this.getMethod(`${EndPoints.getConfig().getDeviceinfo}?deviceId=${data.deviceId}`, data.token)
    }
    getFindMyDeviceEvent(data){
      return this.getMethod(`${EndPoints.getConfig().findMyDeviceEvent}?deviceId=${data.deviceId}&event=${data.event}`, data.token)
    }
  }
  exports.MDM = MDM;
})(typeof exports === "undefined" ? (this["MDM"] = {}) : exports);
