import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { motion, AnimatePresence } from "framer-motion"
import { ActiveCallCard, Activeindvcall, ActiveCurrentCallCard } from '.';
import { onprocessCallState, completedCallState, subscriberType } from '../../constants/constants';
import { sipGroupCallTypes, sipIndividualCallTypes } from '../../utils/sipConfig';
import { getCallieIdToShow } from '../../utils/lib'
import { Title } from '../commom/Title';

const ActiveCalls = (props) => {
  const [activeIndvCalls, setActiveIndvCalls] = useState([]);
  const [activeGrpCalls, setActiveGrpCalls] = useState([]);
  const [activeFavGrpCalls, setActiveFavGrpCalls] = useState([]);
  const [indvOnprocess, setIndvOnprocess] = useState([]);
  const [extendedCall, setExtendedCall] = useState(null);
  const { individualCalls, groupCalls, user, contactList, defaultGroupId } = props;

  useEffect(() => {
    let openFormCallFound = individualCalls.filter(indvl => indvl.isCallExpand === true);
    if (openFormCallFound && openFormCallFound.length === 0) {
      openFormCallFound = groupCalls.filter(grp => grp.isCallExpand === true);
    }
    if (openFormCallFound && openFormCallFound.length > 0) {
      setActiveIndvCalls([]);
      setIndvOnprocess([]);
      setActiveGrpCalls([]);
      setActiveFavGrpCalls([]);
      setExtendedCall(openFormCallFound[0]);
    } else {
      setExtendedCall(null);
      //Active individual call
      const indv = individualCalls.filter(call => !(onprocessCallState.includes(call.stateType) || completedCallState.includes(call.stateType)) && parseInt(call.callPriority) !== 15).sort((a, b) => new Date(b.created) - new Date(a.created));
      //console.log('activated FAs..', global.config.activatedFA); (call.fromId === global.config.activatedFA)
      //Ringing/on process individual call
      const indvCallOnprocess = individualCalls.filter(call => (onprocessCallState.includes(call.stateType)) && parseInt(call.callPriority) !== 15 &&
        (call.isFACall ? ((call.fromId === global.config.activatedFA) || (call.toId === global.config.activatedFA) || (call.fromId === (user && user.profile.mcptt_id))) :
          (call.fromId === (user && user.profile.mcptt_id)))).sort((a, b) => new Date(b.created) - new Date(a.created));
      //console.log("indvCallOnprocess ", indvCallOnprocess, global.config.activatedFA);
      filterActiveGroupCalls();
      setActiveIndvCalls(indv);
      setIndvOnprocess(indvCallOnprocess);
    }
  }, [individualCalls, groupCalls])

  useEffect(() => {
    //filterActiveGroupCalls();
  }, [contactList])


  const filterActiveGroupCalls = () => {
    let favGroupCalls = []
    let nonFavGroupCalls = []
    let defaultGroupCalls = []
    //Active Group call
    const grps = groupCalls.filter(call => !(completedCallState.includes(call.stateType)) && parseInt(call.callPriority) !== 15).sort((a, b) => new Date(b.created) - new Date(a.created));
    defaultGroupCalls = grps.filter(grp => getCallieIdToShow(grp.groupId) === getCallieIdToShow(defaultGroupId));
    favGroupCalls = grps.filter(grp => checkActiveFavGroup(grp.groupId) === true && getCallieIdToShow(grp.groupId) !== getCallieIdToShow(defaultGroupId));
    const mixedDefaultAndFavGrpCalls = [...defaultGroupCalls, ...favGroupCalls];
    nonFavGroupCalls = grps.filter(grp => checkAlreadyInFavGrpsCall(mixedDefaultAndFavGrpCalls, grp.groupId) === false);
    setActiveFavGrpCalls(mixedDefaultAndFavGrpCalls);

    nonFavGroupCalls = nonFavGroupCalls.filter(grp => !grp.isCallExpand);
    setActiveGrpCalls(nonFavGroupCalls)
    //console.log('active and fav group calls', grps, defaultGroupCalls, favGroupCalls, defaultGroupId)
  }

  const checkActiveFavGroup = (groupId) => {
    const groupList = contactList.filter(cont => cont.subscriber_type === subscriberType['GROUP'])
    const filterGroup = groupList.filter(grp => getCallieIdToShow(grp.mcptt_id) === getCallieIdToShow(groupId))
    if (filterGroup.length > 0) {
      const grp = filterGroup[0]
      if (grp.fav === true) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const checkAlreadyInFavGrpsCall = (favGrpCalls, groupId) => {
    const filterGroup = favGrpCalls.filter(grp => getCallieIdToShow(grp.groupId) === getCallieIdToShow(groupId))
    return filterGroup.length > 0 ? true : false
  }

  const isAllowedForPatchCall = () => {
    const indvlCalls = individualCalls.filter(call => !call.isConfCall);
    if (indvlCalls && indvlCalls.length < 2) {
      return true;
    } else {
      let proceesscalls = true;
      let count = 0;
      const indvCallOnprocess = indvlCalls.filter(call => !(call.isConfCall) && (onprocessCallState.includes(call.stateType)) && parseInt(call.callPriority) !== 15 && ((call.fromId === (user && user.profile.mcptt_id)))).sort((a, b) => new Date(b.created) - new Date(a.created));
      const incoming = [...indvlCalls]
        .filter(call => (call.stateType === 'PERSISTED' || call.stateType === 'WAITING') && parseInt(call.callPriority) !== 15 && (call.toId === (user && user.profile.mcptt_id)))
        .sort((a, b) => new Date(b.created) - new Date(a.created));
      if (indvCallOnprocess && indvCallOnprocess.length > 0) {
        count = indvCallOnprocess.length;
      }
      if (incoming && incoming.length > 0) {
        count = count + incoming.length;
      }

      if ((indvlCalls.length - count) < 2) {
        // all calls are either in ringing/waiting or incoming state
        proceesscalls = true;
      } else {
        // now check hold calls
        const allCallsonHoldState = indvlCalls.filter(call => call.actionItem && (call.actionItem.hold == true || call.actionItem.hold_mute == true));
        let cont = (allCallsonHoldState ? allCallsonHoldState.length : 0) + count
        if ((indvlCalls.length - cont) < 2) {
          proceesscalls = true;
        } else {
          proceesscalls = false;
        }
      }
      return proceesscalls;
    }
  }

  const isAllowedForMergeCall = () => {
    if (individualCalls && individualCalls.length < 2) {
      return true;
    } else {
      let proceesscalls = true;
      let count = 0;
      const indvCallOnprocess = individualCalls.filter(call => !(call.isConfCall) && (onprocessCallState.includes(call.stateType)) && parseInt(call.callPriority) !== 15 && ((call.fromId === (user && user.profile.mcptt_id)))).sort((a, b) => new Date(b.created) - new Date(a.created));
      const incoming = [...individualCalls]
        .filter(call => (call.stateType === 'PERSISTED' || call.stateType === 'WAITING') && parseInt(call.callPriority) !== 15 && (call.toId === (user && user.profile.mcptt_id)))
        .sort((a, b) => new Date(b.created) - new Date(a.created));
      if (indvCallOnprocess && indvCallOnprocess.length > 0) {
        count = indvCallOnprocess.length;
      }
      if (incoming && incoming.length > 0) {
        count = count + incoming.length;
      }

      if ((individualCalls.length - count) < 2) {
        // all calls are either in ringing/waiting or incoming state
        proceesscalls = true;
      } else {
        // now check hold calls
        const allCallsonHoldState = individualCalls.filter(call => call.actionItem && (call.actionItem.hold == true || call.actionItem.hold_mute == true));
        let cont = (allCallsonHoldState ? allCallsonHoldState.length : 0) + count
        if ((individualCalls.length - cont) < 2) {
          proceesscalls = true;
        } else {
          proceesscalls = false;
        }
      }
      return proceesscalls;
    }
  }

  const showActiveCallcardInSmallView = () => {
    if (extendedCall.callType === sipGroupCallTypes.broadcast) {
      return (
        <ActiveCurrentCallCard
          data={extendedCall}
          key={"123"}
          isBrd={true}
        />
      )
    }
    else {
      return (
        <ActiveCurrentCallCard
          data={extendedCall}
          key={"456"}
        />
      )
    }
  }

  const showActiveCallcardInBigView = () => {
    return (
      <AnimatePresence>
        {!extendedCall && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div >
              <Title title={"Active Group Calls ( " + activeFavGrpCalls.length + " )"} type="AGC" />
              <div className='DGC ovr-scr-y' style={{ height: '278px' }}>
                <div className="c6-l2-grid m-t-15 ovr-scr-y">
                  {activeFavGrpCalls.map((data, id) => {
                    if (data.callType === sipGroupCallTypes.broadcast) {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          isBrd={true}
                          isExtend={true}
                        />
                      )
                    }
                    else {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          isExtend={true}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            </div>
            <div>
              <Title title={"Other Group Calls ( " + activeGrpCalls.length + " )"} />
              <div className='GC ovr-scr-y' style={{ height: '278px' }}>
                <div className="c6-l2-grid m-t-15 ovr-scr-y">
                  {activeGrpCalls.map((data, id) => {
                    if (data.callType === sipGroupCallTypes.broadcast) {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          isBrd={true}
                          isExtend={true}
                        />
                      )
                    }
                    else {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          isExtend={true}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            </div>
            <div>
              <Title title={"Active Individual Calls ( " + activeIndvCalls.length + " )"} class="m-t-30" type="AIC" />
              <div className='IC ovr-scr-y' style={{ height: '278px' }}>
                <div className="c6-l2-grid m-t-15 ovr-scr-y">
                  {indvOnprocess.map((data, id) => {
                    if (data.callType === sipIndividualCallTypes.duplex) {
                      return (
                        <Activeindvcall
                          data={data}
                          key={id}
                          dial={true}
                          disableCallPatch={true}
                          disableCallMerge={true}
                          isExtend={true}
                        />
                      )
                    }
                    else {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          dial={true}
                          disableCallPatch={true}
                          disableCallMerge={true}
                          isExtend={true}
                        />
                      )
                    }
                  })}
                  {activeIndvCalls.map((data, id) => {
                    if (data.callType === sipIndividualCallTypes.duplex) {
                      return (
                        <Activeindvcall
                          data={data}
                          key={id}
                          disableCallPatch={isAllowedForPatchCall()}
                          disableCallMerge={isAllowedForMergeCall()}
                          isExtend={true}
                        />
                      )
                    }
                    else if (data.callType === sipIndividualCallTypes.ambientListening) {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          isAmb={true}
                          disableCallPatch={isAllowedForPatchCall()}
                          disableCallMerge={isAllowedForMergeCall()}
                          isExtend={true}
                        />
                      )
                    }
                    else {
                      return (
                        <ActiveCallCard
                          data={data}
                          key={id}
                          disableCallPatch={isAllowedForPatchCall()}
                          disableCallMerge={isAllowedForMergeCall()}
                          isExtend={true}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    )
  }

  return (
    // <div>
    //   {extendedCall ? showActiveCallcardInSmallView() : showActiveCallcardInBigView()}
    // </div>
    <div>
      {extendedCall ? showActiveCallcardInSmallView() : showActiveCallcardInBigView()}
    </div>
  )

  // return (
  //   <React.Fragment>
  //     <div >
  //       <Title title={"Active Group Calls ( " + activeFavGrpCalls.length + " )"} type="AGC" />
  //       <div className='DGC ovr-scr-y' style={{ height: '278px' }}>
  //         <div className="c6-l2-grid m-t-15 ovr-scr-y">
  //           {activeFavGrpCalls.map((data, id) => {
  //             if (data.callType === sipGroupCallTypes.broadcast) {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                   isBrd={true}
  //                 />
  //               )
  //             }
  //             else {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                 />
  //               )
  //             }
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //     <div>
  //       <Title title={"Other Group Calls ( " + activeGrpCalls.length + " )"} />
  //       <div className='GC ovr-scr-y' style={{ height: '278px' }}>
  //         <div className="c6-l2-grid m-t-15 ovr-scr-y">
  //           {activeGrpCalls.map((data, id) => {
  //             if (data.callType === sipGroupCallTypes.broadcast) {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                   isBrd={true}
  //                 />
  //               )
  //             }
  //             else {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                 />
  //               )
  //             }
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //     <div>
  //       <Title title={"Active Individual Calls ( " + activeIndvCalls.length + " )"} class="m-t-30" type="AIC" />
  //       <div className='IC ovr-scr-y' style={{ height: '278px' }}>
  //         <div className="c6-l2-grid m-t-15 ovr-scr-y">
  //           {indvOnprocess.map((data, id) => {
  //             if (data.callType === sipIndividualCallTypes.duplex) {
  //               return (
  //                 <Activeindvcall
  //                   data={data}
  //                   key={id}
  //                   dial={true}
  //                   disableCallPatch={true}
  //                   disableCallMerge={true}
  //                 />
  //               )
  //             }
  //             else {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                   dial={true}
  //                   disableCallPatch={true}
  //                   disableCallMerge={true}
  //                 />
  //               )
  //             }
  //           })}
  //           {activeIndvCalls.map((data, id) => {
  //             if (data.callType === sipIndividualCallTypes.duplex) {
  //               return (
  //                 <Activeindvcall
  //                   data={data}
  //                   key={id}
  //                   disableCallPatch={isAllowedForPatchCall()}
  //                   disableCallMerge={isAllowedForMergeCall()}
  //                 />
  //               )
  //             }
  //             else if (data.callType === sipIndividualCallTypes.ambientListening) {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                   isAmb={true}
  //                   disableCallPatch={isAllowedForPatchCall()}
  //                   disableCallMerge={isAllowedForMergeCall()}
  //                 />
  //               )
  //             }
  //             else {
  //               return (
  //                 <ActiveCallCard
  //                   data={data}
  //                   key={id}
  //                   disableCallPatch={isAllowedForPatchCall()}
  //                   disableCallMerge={isAllowedForMergeCall()}
  //                 />
  //               )
  //             }
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //   </React.Fragment>
  // )

  /*return (
    <React.Fragment>
      <div>
        <Title title={"Calls ( " + activeGrpCalls.length + " )"} />
        <div className="call-divider m-t-2 m-b-10">

        </div>
        <div className="m-t-5">
          {activeGrpCalls.map((data, id) => {
            if (data.callType === sipGroupCallTypes.broadcast) {
              return (
                <ActiveCallCard
                  data={data}
                  key={id}
                  isBrd={true}
                />
              )
            }
            else {
              return (
                <ActiveCallCard
                  data={data}
                  key={id}
                />
              )
            }
          })}
        </div>
      </div>
    </React.Fragment>
  )*/

}

const mapStateToProps = ({ communication, auth }) => {
  const { individualCalls, groupCalls, contactList, defaultGroupId } = communication;
  const { user } = auth;

  return {
    individualCalls,
    groupCalls,
    user,
    contactList,
    defaultGroupId
  };
};

export default connect(mapStateToProps, {})(ActiveCalls);
