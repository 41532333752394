/** @format */

import React, { useEffect, useState } from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { Radio, Checkbox, FormControlLabel, InputLabel, MenuItem, FormControl, Select, RadioGroup, Grid, Box } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ConfigCommonSetting = (props) => {
    const {
        nextButtonHandler, data, purpose
    } = props;
    const [configData, setCommonSettingData] = useState({ ...data })
    const [emptyPassword, setEmptyPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({
        name: '',
        pushoptions: '',
        deviceType: ''
    });
    const [error, setError] = useState('');
    //------------------------------------------------------
    const locationOption = [
        { text: "Do Not Track", value: "stop" },
        { text: "By GPS", value: "gps" },
        { text: "Over Wifi", value: "network" }
    ];
    const pushNotificationOption = [
        { text: "HTTP Polling", value: "polling" },
        { text: "MQTT protocol (save battery)", value: "mqttWorker" },
        { text: "MQTT protocol (instant delivery)", value: "mqttAlarm" }
    ]
    const manageTimeZoneOption = [
        { text: "Do Not Manage", value: "" },
        { text: "Auto", value: "auto" },
        { text: "Manual", value: "manual" }
    ]
    const passwordOption = [
        { text: "None", value: null },
        { text: "Present Password", value: "present" },
        { text: "Easy (min length 6)", value: "easy" },
        { text: "Moderate (min length 8, letters & digits", value: "moderate" },
        { text: "Strong (8+ characters, smallcase, uppercase, special characters", value: "strong" }
    ]
    const useStyles = makeStyles((theme) => ({
        // root: {
        //     width: "100%",
        //     maxWidth: 360,
        // },
        // formControl: {
        //     width: "100%",
        //     marginTop: "5px",
        // },
        // formControlFA: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // listMember_all: {
        //     // width: 250,
        //     maxHeight: 200,
        // },
        // listItemFA: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // tetraSwitch: {
        //     fontFamily: "Muli",
        //     marginTop: "2px",
        //     marginRight: "1px",
        //     marginLeft: "0px",
        // },
        // noOfRegTextField: {
        //     width: "40%",
        //     height: "25px",
        // },
        // ueInfoList: {
        //     padding: "5px",
        //     fontFamily: "Muli",
        // },
        // formControlUEType: {
        //     width: "80%",
        //     height: "60px",
        //     marginTop: "5px",
        // },
        // mcxDataIdInput: {
        //     backgroundColor: "rgb(232, 232, 232) !important",
        // },
        // tickSize: {
        //     transform: "scale(1.3)",
        //     marginLeft: "20px",
        // },
        // ml0: {
        //     marginTop: "10px",
        // },
        // mleft0: {
        //     marginLeft: "0px",
        // },
        // errorIndicator: {
        //     background: "#ffeded",
        // },
        // paper: {
        //     position: 'relative',
        //     background: '#e8e8e8',
        //     overflow: 'hidden'
        // }

        root: {
            backgroundColor: "#252524",
            //backgroundColor: theme.palette.background.default,
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            //height: "70%",
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
        },
        formControlFA: {
            width: "100%",
            height: "65px",
            marginTop: "2px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#383736"
        },
        listItemFA: {
            padding: "5px",
            fontFamily: "Muli",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        productTypeClass: {
            width: "90%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            border: "solid",
            borderRadius: 10,
            borderWidth: "2px",
            borderColor: "#4B4A4A",
            marginTop: 5,
            marginBottom: 10
        },
        divList: {
            width: '100%',
            marginBottom: '13px',
        },
        lblStyle: {
            width: '100%',
            color: "#D0D3D4",
            fontSize: 18,
            fontWeight: 600
        },
        tetraSwitch: {
            fontFamily: "Muli",
            marginTop: "2px",
            marginRight: "1px",
            marginLeft: "0px",
            color: "#D0D3D4",
            fontSize: 17,
            fontWeight: 600
        },
        datePickerStyle: {
            color: "black",
            padding: "10px",
            fontFamily: "Sans-Serif",
            width: '60%',
            border: "solid",
            borderRadius: 20,
            borderWidth: "2px",
            borderColor: "#D3D3D3",
        },
        tickSize: {
            marginTop: 7,
        }
    }));

    const theme = createTheme({
        palette: {
            primary: {
                main: "#006400",
            },
            secondary: {
                main: "#fdce2a",
            },
        },
    });
    const validate = () => {
        const requiredFields = ['pushoptions', 'deviceType', 'name'];
        let err = false;
        let errors = {
            name: '',
            pushoptions: '',
            deviceType: ''
        }
        for (let i = 0; i < 3; i++) {
            let key1 = requiredFields[i];
            if (key1 === 'name' && (!configData[key1] || configData[key1].trim() === '')) {
                err = true;
                errors.name = 'Name is required'
            }
            if (key1 === 'pushoptions' && !configData[key1]) {
                err = true;
                errors.pushoptions = 'PushOption is required'
            }
            if (key1 === 'deviceType' && !configData[key1]) {
                err = true;
                errors.deviceType = 'DeviceType is required'
            }
        }

        if (err) {
            setFormErrors(errors);
            return;
        }
        nextButtonHandler(0, configData)
    }
    const classes = useStyles();
    // const nameChangeHandler = (e) => {
    //     // let data = { ...commonSettingData }
    //     data[e.target.name] = e.target.value
    //     setCommonSettingData({ ...data })
    // }
    // const onSelectHandler = (e) => {
    //     // let data = { ...commonSettingData }
    //     data[e.target.name] = e.target.value
    //     setCommonSettingData({ ...data })
    // }
    // const checkBoxHandler = (e) => {
    //     // let data = { ...commonSettingData }
    //     data[e.target.name] = !data[e.target.name]
    //     setCommonSettingData({ ...data })
    // }
    // const radioGroupChangeHandler = (e) => {
    //     // let data = { ...commonSettingData }
    //     data[e.target.name] = e.target.value
    //     setCommonSettingData({ ...data })
    // }
    const onChangeHandler = (e) => {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        console.log("GPS checkbox", e.target.checked, e.target.value);
        setCommonSettingData({
            ...configData,
            [e.target.name]: value
        });
        if (e.target.name == 'name') {
            if (e.target.value.trim()) {
                setFormErrors({ ...formErrors, name: '' })
            }
            else {
                setFormErrors({ ...formErrors, name: 'Name is required' })
            }

        }
        if (e.target.name == 'deviceType' && e.target.value) {
            setFormErrors({ ...formErrors, deviceType: '' })

        }
        if (e.target.name == 'pushoptions' && e.target.value) {
            setFormErrors({ ...formErrors, pushoptions: '' })
        }
    }
    const checkBoxHandler = (e) => {
        const value = e.target.value
        setCommonSettingData({ ...configData, [e.target.name]: value })
    }
    console.log("configdata", configData);
    return (
        <div class={classes.root}>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={12}>
                    <Box className="tab1-account" width={'100%'} marginLeft={0}>
                        <Box class="form-group" paddingX={10} paddingY={0} >
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Name</label>
                            </Box>
                            <input
                                type="text"
                                class="input-control-flat"
                                id="name"
                                name="name"
                                value={configData.name || ""}
                                onChange={onChangeHandler}
                            />
                        </Box>
                        {formErrors.name ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>{formErrors.name}**</h3>
                            </Box>
                            : null}
                        <Box class="form-group" paddingX={10} paddingY={0} >
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Description</label>
                            </Box>
                            <input
                                type="text"
                                class="input-control-flat"
                                id="description"
                                name="description"
                                value={configData.description || ""}
                                onChange={onChangeHandler}
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box flex={0.15} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Administrator Password</label>
                            </Box>
                            {/* <FormControl
                            variant="filled"
                            className={classes.formControlUEType}

                        > */}
                            <input
                                type="password"
                                class="input-control-flat"
                                id="passworddd"
                                name="password"
                                // value={configData.password || ""}
                                onChange={onChangeHandler}
                            />
                            {/* </FormControl> */}
                            {/* {emptyPassword === true ? (
                                <label class="error-handling-lbl">
                                    Password should be atleast 8 characters
                                </label>
                            ) : null} */}
                        </Box>
                        <Box className="form-group" paddingRight={4} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1} >
                                <label className="attribute-heading" style={{ color: 'whitesmoke' }}>Device Type*</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="config" className={classes.listItemFA} focused={false} >
                                    Device Type
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="deviceType"
                                    name="deviceType"
                                    value={configData.deviceType || props.deviceTypeList.id}
                                    onChange={onChangeHandler}
                                    disabled={purpose == "edit"}
                                    disableUnderline={true}
                                >
                                    {props.deviceTypeList &&
                                        props.deviceTypeList.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.id}>{uetype.manufacturer} {uetype.brand}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        {formErrors.deviceType ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>{formErrors.deviceType}**</h3>
                            </Box>
                            : null}
                        <Box class="form-group" paddingX={10} paddingY={0}>
                            <Box style={{ flexDirection: 'row', display: 'flex' }}>
                                <Box flex={0.3} mr="0.5em" marginTop={2} marginBottom={1}>
                                    <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Status bar lock</label>
                                </Box>
                                <FormControlLabel
                                    className={classes.tickSize}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="blockstatusbar"
                                            name="blockstatusbar"
                                            checked={configData.blockstatusbar ? true : false}
                                            onChange={onChangeHandler}
                                        />
                                    }
                                />
                            </Box>
                            <label class="staus-barlabel">This setting is deprecated and works well on Android 6 and below. To lock the status bar on newer Android versions, use the kiosk mode (MDM settings).</label>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.3} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label className="attribute-heading" style={{ color: 'whitesmoke' }}>Auto-start Foreground</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        id="autostartForeground"
                                        name="autostartForeground"
                                        checked={configData.autostartForeground ? true : false}
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" addingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}> Update Location</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="ueTypeID" className={classes.listItemFA} focused={false} >
                                    Location
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="requestupdates"
                                    name="requestupdates"
                                    value={configData.requestupdates || ""}
                                    onChange={onChangeHandler}
                                    disableUnderline={true}
                                >
                                    {locationOption &&
                                        locationOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}  >{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="form-group" addingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Push Notification*</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="pushNotificationLabel" className={classes.listItemFA} focused={false} >
                                    Push Notification*
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="pushoptions"
                                    name="pushoptions"
                                    value={configData.pushoptions || ""}
                                    onChange={onChangeHandler}
                                    disableUnderline={true}
                                >
                                    {pushNotificationOption &&
                                        pushNotificationOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        {formErrors.pushoptions ?
                            <Box style={{ display: "flex", flexDirection: "row-reverse" }} className="my-2">
                                <h3 style={{ color: "red" }}>{formErrors.pushoptions}**</h3>
                            </Box>
                            : null}
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>GPS</label>
                            </Box>
                            <Box flex={1.0} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="gps"
                                    value={configData.gps || ""}
                                    onChange={checkBoxHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel value="" style={{ color: 'whitesmoke' }} checked={configData.gps === "" || configData.gps === undefined ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Any" />
                                        <FormControlLabel value={0} style={{ color: 'whitesmoke' }} checked={configData.gps === "0" || configData.gps === 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Disabled" />
                                        <FormControlLabel value={1} style={{ color: 'whitesmoke' }} checked={configData.gps === "1" || configData.gps === 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Enabled" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Bluetooth</label>
                            </Box>
                            <Box flex={1.0} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="bluetooth"
                                    value={configData.bluetooth || ""}
                                    onChange={onChangeHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel value="" style={{ color: 'whitesmoke' }} checked={configData.bluetooth === "" || configData.bluetooth === undefined || configData.bluetooth === null ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Any" />
                                        <FormControlLabel value="0" style={{ color: 'whitesmoke' }} checked={configData.bluetooth === "0" || configData.bluetooth === 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Disabled" />
                                        <FormControlLabel value="1" style={{ color: 'whitesmoke' }} checked={configData.bluetooth === "1" || configData.bluetooth === 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Enabled" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Wi-Fi</label>
                            </Box>
                            <Box flex={1.0} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="wifi"
                                    value={configData.wifi || ""}
                                    onChange={onChangeHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel value="" style={{ color: 'whitesmoke' }} checked={configData.wifi == "" || configData.wifi === undefined || configData.wifi === null ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Any" />
                                        <FormControlLabel value="0" style={{ color: 'whitesmoke' }} checked={configData.wifi === "0" || configData.wifi === 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Disabled" />
                                        <FormControlLabel value="1" style={{ color: 'whitesmoke' }} checked={configData.wifi === "1" || configData.wifi === 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Enabled" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Mobile data</label>
                            </Box>
                            <Box flex={1.0} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="mobiledata"
                                    value={configData.mobiledata || ""}
                                    onChange={onChangeHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel value="" style={{ color: 'whitesmoke' }} checked={configData.mobiledata == "" || configData.mobiledata === undefined || configData.mobileData === null ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Any" />
                                        <FormControlLabel value="0" style={{ color: 'whitesmoke' }} checked={configData.mobiledata === "0" || configData.mobiledata === 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Disabled" />
                                        <FormControlLabel value="1" style={{ color: 'whitesmoke' }} checked={configData.mobiledata === "1" || configData.mobiledata === 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Enabled" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Manage Brightness</label>
                            </Box>
                            <Box flex={1} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="autobrightness"
                                    value={configData.autobrightness || ""}
                                    onChange={onChangeHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel value="" style={{ color: 'whitesmoke' }} checked={configData.autobrightness == "" || configData.autobrightness === undefined || configData.autobrightness === null ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="None" />
                                        <FormControlLabel value="0" style={{ color: 'whitesmoke' }} checked={configData.autobrightness === "0" || configData.autobrightness === 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Value" />
                                        <FormControlLabel value="1" style={{ color: 'whitesmoke' }} checked={configData.autobrightness === "1" || configData.autobrightness === 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Auto" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Block USB storage</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                name="usbstorage"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.usbstorage ? true : false}
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Manage Screen Timeout</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                name="managetimeout"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.managetimeout ? true : false}
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Lock volume</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                name="lockvolume"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.lockvolume ? true : false}
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Manage Volume</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                name="managevolume"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.managevolume ? true : false}
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box className="form-group" paddingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Manage Time Zone</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="manageTZLabel" className={classes.listItemFA} focused={false} >
                                    Time Zone
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="timezone"
                                    value={configData.timezone || ""}
                                    onChange={onChangeHandler}
                                    name="timezone"
                                    disableUnderline={true}
                                >
                                    {manageTimeZoneOption &&
                                        manageTimeZoneOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>System Update</label>
                            </Box>
                            <Box flex={1.0} mr="0.5em" marginTop={1} marginBottom={1}>
                                <RadioGroup
                                    aria-label="RecordType"
                                    name="systemupdatetype"
                                    value={configData.systemupdatetype || ""}
                                    onChange={onChangeHandler}
                                    className={classes.formGroupDirection}
                                >
                                    <Box className="status-bar-orientation">
                                        <FormControlLabel style={{ color: 'whitesmoke' }} value="0" checked={configData.systemupdatetype == 0 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Default" />
                                        <FormControlLabel style={{ color: 'whitesmoke' }} value="1" checked={configData.systemupdatetype == 1 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Immediately" />
                                        <FormControlLabel style={{ color: 'whitesmoke' }} value="2" checked={configData.systemupdatetype == 2 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Scheduled" />
                                        <FormControlLabel style={{ color: 'whitesmoke' }} value="3" checked={configData.systemupdatetype == 3 ? true : false} control={<Radio className={classes.radioColor} color="primary" />} label="Postponed" />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box className="form-group" paddingRight={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Box flex={1} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Password requirements</label>
                            </Box> */}
                            <FormControl
                                variant="filled"
                                className={classes.formControlFA}
                            >
                                <InputLabel id="passwordReqLabel" className={classes.listItemFA} focused={false} >
                                    Password requirements
                                </InputLabel>
                                <Select
                                    className={classes.listItemFA}
                                    id="passwordmode"
                                    value={configData.passwordmode || ""}
                                    onChange={onChangeHandler}
                                    name="passwordmode"
                                    disableUnderline={true}
                                >
                                    {passwordOption &&
                                        passwordOption.map((uetype) => {
                                            return (
                                                <MenuItem value={uetype.value}>{uetype.text}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>WiFi settings on connection error</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.wifiSettings ? true : false}
                                        name="wifiSettings"
                                    // onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Use with default launcher</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.rundefaultlauncher ? true : false}
                                        name="rundefaultlauncher"
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                        <Box class="form-group" paddingX={10} paddingY={0} style={{ flexDirection: 'row', display: 'flex' }}>
                            <Box flex={0.5} mr="0.5em" marginTop={2} marginBottom={1}>
                                <label class="attribute-heading" style={{ color: 'whitesmoke' }}>Disable screen capture</label>
                            </Box>
                            <FormControlLabel
                                className={classes.tickSize}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={configData.disablescreenshots ? true : false}
                                        name="disablescreenshots"
                                        onChange={onChangeHandler}
                                    />
                                }
                            />
                        </Box>
                    </Box>
                    <Box>
                        <h3 style={{ color: "red" }}>{error}</h3>
                    </Box>
                    <Box className="submit-btns">
                        <button
                            class="update-btn-profile"
                            type="button"
                            onClick={() => validate()}
                        // disabled={configData && configData['password'] && configData['password'].length > 0 ? true : false}
                        >
                            Next
                        </button>
                    </Box>
                </Grid>
            </Grid>
        </div >

    );
};
export default connect()(ConfigCommonSetting);
