import React, { useState, useEffect } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import {
    getCallRecord,
    setPage,
    setPaginationModel
    // setTotalPage, setHasMore, setLoader, setShowingNextData 
} from "../../modules/actions/playerAction";
import RegisterTableRow from './RegisterTableRow';
import { Grid, Button } from '@material-ui/core';
import { Modal as ModalBootStrap } from "react-bootstrap";
import RegisterRecordInfoTab from './RegisterRecordInfoTab';
import ArchiveRecordings from './ArchiveRecordings';
import { Title } from '../commom';
import { DataGrid } from "@mui/x-data-grid";
import { showTime } from './definedFunction';

const RegisterTable = (props) => {
    const { callRecord, pagination, callRecordReqObj ,paginationModel,setPaginationModel} = props;

    const [isSearch, setSearched] = useState(false);
    const [filtered, setFiltered] = useState();
    const [lastfiltered, setLastFiltered] = useState();
    const [isRegisterInfoModalOpen, setIsRegisterInfoModalOpen] = useState(false);
    const [isRegisterRecordSelected, setRegisterRecordSelected] = useState();
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [localPagination, setLocalPagination] = useState(paginationModel)
    const setLocal = (paginationValue, event)=>{
        setLocalPagination(paginationValue)
        setPaginationModel(paginationValue)
    }
    useEffect(() => {
        fetchPage(localPagination.page + 1, localPagination.pageSize)
    }, [localPagination])

    useEffect(() => {
        if (!isSearch) {
            setFiltered(callRecord);
            setLastFiltered(callRecord);

        }

        console.log("Search Call Record", callRecord)
    }, [callRecord])

    const archiveModalhandleClose = () => {
        setIsArchiveModalOpen(false);
    };
    const handleShowArchiveButton = () => {
        setIsArchiveModalOpen(true);
    }

    const getCallRecordList = (filterData) => {
        let params = callRecordReqObj;
        params.page = filterData.page;
        params.limit = filterData.pageSize;
        console.log("show button is clicked", params);
        props.getCallRecord(params);
    }

    const fetchPage = (page, pageSize) => {
    
        let filterData = {
            page: page,
            pageSize: pageSize,
        }
        console.log('fetchPage -->', page);
        getCallRecordList(filterData)
    }


    const searchRecord = (searchRecord) => {
        let filterRecord;
        const search = searchRecord.toLowerCase();
        if (search) {
            filterRecord = lastfiltered.filter(record =>
                (record.callID && record.callID.toLowerCase().includes(search)) ||
                (record.calleeDisplayName && record.calleeDisplayName.toString().toLowerCase().includes(search)) ||
                (record.callerDisplayName && record.callerDisplayName.toString().toLowerCase().includes(search)) ||
                (record.displayGroupName && record.displayGroupName.toLowerCase().includes(search))
            )
            setFiltered(filterRecord);
            setSearched(true);
        }
        else {
            setFiltered(lastfiltered);
            setSearched(false);
        }
    }

    const userFilterModalhandleClose = () => {
        setIsRegisterInfoModalOpen(false);
        setRegisterRecordSelected(null);
    }

    const handleShowModal = (data, idx) => {
        console.log("handle show modal----", data.row);
        setIsRegisterInfoModalOpen(true);
        setRegisterRecordSelected(data.row);
    }

    const columns = [
        {
            field: 'user', headerName: 'User', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showUser(row)}`,
        },
        {
            field: 'ua', headerName: 'UA', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val", align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showUA(row)}`,
        },
        {
            field: 'userType', headerName: 'User Type', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
            valueGetter: (value, row) => `${showUserType(row)}`,
        },
        {
            field: 'time', headerName: 'Time', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${showTime(row.recordStartTime)}`,
        },
        {
            field: 'sessionType', headerName: 'Type', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center",
        },
        {
            field: 'ueid', headerName: 'UEID', width: 208, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${row.callerUeId.split("@")[0]}`
        },
        {
            field: 'impu', headerName: 'IMPU', width: 230, sortable: false,
            headerClassName: "list-head-Val", cellClassName: "list-row-Val",
            align: "center", headerAlign: "center", valueGetter: (value, row) => `${row.callerIMPU.split("@")[0]}`
        },
    ];

    const showUser = (data) => {
        if (data) {
            if (data.callerDisplayName)
                return data.callerDisplayName;
        }
        return "";
    }

    const showUA = (data) => {
        if (data) {
            if (data.callerUA)
                return data.callerUA;
        }
        return "";
    }

    const showUserType = (data) => {
        if (data) {
            if (data.callerUserType)
                return data.callerUserType;
        }
        return "";
    }
    
    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={10}>
                    <Title title="Register Recordings" type="TD" search={searchRecord} />
                </Grid>
                <Grid item xs={2} style={{ display: "flex", justifyContent: "end" }} >
                    <Button style={{ backgroundColor: "#ffb01f", color: "black", width: "80%" }} variant="container" onClick={handleShowArchiveButton}>Show Archives</Button>
                </Grid>
            </Grid>
            <div style={{ height: 900, marginTop: "5px" }}>
                <DataGrid
                    rows={filtered ? filtered : []}
                    getRowId={(row) => row.id}
                    onRowClick={handleShowModal}
                    columns={columns}
                    rowHeight={68}
                    // hideFooter
                    // hideFooterPagination
                    // hideFooterSelectedRowCount
                    disableColumnMenu
                    rowCount={pagination.rowCount || pagination.pageSize * pagination.totalPages}
                    pagination
                    pageSizeOptions={[10, 20, 25]}
                    paginationModel={localPagination}
                    onPaginationModelChange={setLocal}
                    paginationMode="server"
                    localeText={{ noRowsLabel: "" }}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 0,
                        bottom: params.isLastVisible ? 0 : 0
                    })}
                    sx={{
                        boxShadow: 2,
                        border: "none",
                        borderBottom: "none",
                        '& .MuiDataGrid-cell:hover': {
                            color: '#ffb01f',
                        },
                        '& .MuiDataGrid': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: "#404040",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            borderColor: "gray",
                        },
                        "& .MuiDataGrid-sortIcon": {
                            opacity: 1,
                            color: "white",
                        },
                        "& .MuiDataGrid-menuIconButton": {
                            opacity: 1,
                            color: "white"
                        },
                        // "& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
                        //     color: "white"
                        // },
                        "& .MuiTablePagination-root": {
                            color: "white",
                        },
                        "& .MuiTablePagination-selectLabel": {
                            color: "white"
                        },
                        "& svg:not(:root)": {
                            color: 'white'
                        },
                        "& .css-levciy-MuiTablePagination-displayedRows": {
                            color: 'white'
                        },
                        '& .MuiDataGrid-filler': {
                            backgroundColor: "#262626",
                        },
                    }}

                />
            </div>
            <ModalBootStrap
                show={isRegisterInfoModalOpen}
                onHide={userFilterModalhandleClose}
                scrollable={true}
                // size={"xl"}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>Registeration</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        boxShadow: 1,
                        border: 2,
                        p: 1,
                        padding: "8px",
                        margin: "2px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        backgroundColor: "#121212",
                        borderRadius: "16px"
                    }}
                >
                    {isRegisterRecordSelected ? <RegisterRecordInfoTab data={isRegisterRecordSelected} /> : ""}
                </ModalBootStrap.Body>
            </ModalBootStrap>
            <ModalBootStrap
                show={isArchiveModalOpen}
                onHide={archiveModalhandleClose}
                scrollable={true}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                // dialogClassName="modal-65w"
                centered
                style={{ backgroundColor: " rgba(0,0,0,0.5)" }}
                className="special_modal"
            >
                <ModalBootStrap.Header
                    closeButton
                    style={{ border: "0px", backgroundColor: "#282828" }}
                >
                    <ModalBootStrap.Title>Archival Management</ModalBootStrap.Title>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body
                    style={{
                        bgcolor: "background.paper",
                        minHeight: "45vh",
                        boxShadow: 1,
                        border: 2,
                        margin: "8px",
                        backgroundColor: "#121212",
                        borderRadius: "16px",
                        padding: "0px"
                    }}
                >
                    <ArchiveRecordings />
                </ModalBootStrap.Body>
            </ModalBootStrap>
        </React.Fragment>
    )
}


const mapStateToProps = ({ player }) => {
    return {
        callRecord: player.callRecord,
        pagination: player.pagination,
        callRecordReqObj: player.callRecordReqObj,
        currentPage: player.currentPage,
        totalPage: player.totalPage,
        hasMore: player.hasMore,
        loading: player.loading,
        showingNextData: player.showingNextData,
        paginationModel:player.paginationModel
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCallRecord,
            setPage,
            setPaginationModel
            // setTotalPage, setHasMore, setLoader, setShowingNextData
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTable);