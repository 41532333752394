import {
    SET_CURRENT_TAB,
    FECTH_DEVICE_LIST,
    FETCH_APP_LIST,
    FETCH_CONFIG_LIST,
    DELETE_DEVICE,
    DELETE_CONFIG,
    DELETE_APP,
    FETCH_AUTH_TOKEN,
    UPDATE_CONFIG,
    FETCH_CONFIG_APPLICATION,
    SET_CONFIG_APPLICATION,
    ADD_CONFIG_APP_RELN,
    ADD_DEVICE,
    UPDATE_DEVICE,
    PULL_LOGS,
    SET_APP_RELN,
    SET_TENANT_ID,
    GET_TENANT_LIST,
    GET_DEVICE_ID_LIST,
    UPDATE_DEVICE_BY_DEVICE,
    GET_DEVICE_ID,
    SET_DEVICE_ID,
    FECTH_DEVICE_TYPE_LIST,
    ADD_DEVICE_TYPE,
    DELETE_DEVICE_TYPE,
    FETCH_APP_CONFIG_LIST,
    FETCH_DEVICE_INVENTORY,
    ALLOCATE_DEVICE,
    SET_DEVICE_INVENTORY,
    SET_SEARCHED_TEXT,
    SET_FILTERED_DATA,
    UPDATE_DEVICE_BY_USER,
    UPLOAD_APP,
    GET_CONFIG_DEVICE,
    UPDATE_CONFIG_DEVICE,
    FETCH_DEVICE_DASHBOARD,
    FETCH_DEVICE_INFO,
    GET_FINDMYDEVICE_EVENT,
    
   
    
} from "./type"

const actions = {
    setCurrentTab: (data) => ({ type: SET_CURRENT_TAB, data }),
    fetchDeviceList: (data) => ({ type: FECTH_DEVICE_LIST, data }),
    fetchDeviceTypeList: () => ({ type: FECTH_DEVICE_TYPE_LIST }),
    fetchAppList: (data) => ({ type: FETCH_APP_LIST, data }),
    fetchAppConfigList: (data) => ({ type: FETCH_APP_CONFIG_LIST, data }),
    fetchConfigList: (data) => ({ type: FETCH_CONFIG_LIST, data }),
    fetchDeviceInventory: (data) => ({ type: FETCH_DEVICE_INVENTORY, data }),
    updateDeviceInventory: (data) => ({ type: SET_DEVICE_INVENTORY, data }),
    allocateDevice: (data) => ({ type: ALLOCATE_DEVICE, data }),
    setTenantId: (data) => ({ type: SET_TENANT_ID, data }),
    deleteDevice: (data) => ({ type: DELETE_DEVICE, data }),
    deleteDeviceType: (data) => ({ type: DELETE_DEVICE_TYPE, data }),
    deleteConfig: (data) => ({ type: DELETE_CONFIG, data }),
    deleteApp: (data) => ({ type: DELETE_APP, data }),
    fetchAuthToken: (data) => ({ type: FETCH_AUTH_TOKEN, data }),
    updateConfig: (data) => ({ type: UPDATE_CONFIG, data }),
    setConfigApp: (data) => ({ type: SET_CONFIG_APPLICATION, data }),
    fetchConfigApp: (data) => ({ type: FETCH_CONFIG_APPLICATION, data }),
    addConfigAppReln: (data) => ({ type: ADD_CONFIG_APP_RELN, data }),
    addDevice: (data) => ({ type: ADD_DEVICE, data }),
    addDeviceType: (data) => ({ type: ADD_DEVICE_TYPE, data }),
    updateDevice: (data) => ({ type: UPDATE_DEVICE, data }),
    uploadApp: (data) => ({ type: UPLOAD_APP, data }),
    pullLogs: (data) => ({ type: PULL_LOGS, data }),
    appReln: (data) => ({ type: SET_APP_RELN, data }),
    getTenantList: (data) => ({ type: GET_TENANT_LIST, data }),
    getDeviceIdList: (data) => ({ type: GET_DEVICE_ID_LIST, data }),
    updateDeviceByDeviceId: (data) => ({ type: UPDATE_DEVICE_BY_DEVICE, data }),
    updateDeviceByUser: (data) => ({ type: UPDATE_DEVICE_BY_USER, data }),
    getDeviceId: (data) => ({ type: GET_DEVICE_ID, data }),
    setDeviceId: (data) => ({ type: SET_DEVICE_ID, data }),
    setSearchedText: (data) => ({ type: SET_SEARCHED_TEXT, data }),
    setFileteredData: (data) => ({ type: SET_FILTERED_DATA, data }),
    fetchConfigurationByDeviceId: (data) => ({ type: GET_CONFIG_DEVICE, data }),
    restConfigDevices: (data) => ({ type: UPDATE_CONFIG_DEVICE, data }),
    fetchDeviceDashboard:(data)=>({type:FETCH_DEVICE_DASHBOARD,data}),
    fetchDeviceInfo:(data)=>({type:FETCH_DEVICE_INFO,data}),
    getFindMyDeviceEvent:(data)=>({type:GET_FINDMYDEVICE_EVENT,data}),

}

export const setCurrentTab = (data) => {
    return dispatch => dispatch(actions.setCurrentTab(data))
}
export const fetchDeviceList = (data) => {
    return dispatch => dispatch(actions.fetchDeviceList(data))
}
export const fetchDeviceTypeList = () => {
    return dispatch => dispatch(actions.fetchDeviceTypeList())
}
export const fetchAppList = (data) => {
    return dispatch => dispatch(actions.fetchAppList(data))
}
export const fetchAppConfigList = (data) => {
    return dispatch => dispatch(actions.fetchAppConfigList(data))
}
export const fetchConfigList = (data) => {
    return dispatch => dispatch(actions.fetchConfigList(data))
}
export const setTenantId = (data) => {
    return dispatch => dispatch(actions.setTenantId(data))
}
export const deleteDevice = (data) => {
    return dispatch => dispatch(actions.deleteDevice(data))
}
export const deleteDeviceType = (data) => {
    return dispatch => dispatch(actions.deleteDeviceType(data))
}
export const deleteConfig = (data) => {
    return dispatch => dispatch(actions.deleteConfig(data))
}
export const deleteApp = (data) => {
    return dispatch => dispatch(actions.deleteApp(data))
}
export const fetchAuthToken = (data) => {
    return dispatch => dispatch(actions.fetchAuthToken(data))
}
export const updateConfig = (data) => {
    return dispatch => dispatch(actions.updateConfig(data))
}
export const setConfigApp = (data) => {
    return dispatch => dispatch(actions.setConfigApp(data))
}
export const fetchConfigApp = (data) => {
    return dispatch => dispatch(actions.fetchConfigApp(data))
}
export const addConfigAppReln = (data) => {
    return dispatch => dispatch(actions.addConfigAppReln(data))
}
export const addDevice = (data) => {
    return dispatch => dispatch(actions.addDevice(data))
}
export const updateDevice = (data) => {
    return dispatch => dispatch(actions.updateDevice(data))
}
export const uploadApp = (data) => {
    return dispatch => dispatch(actions.uploadApp(data))
}
export const addDeviceType = (data) => {
    return dispatch => dispatch(actions.addDeviceType(data))
}
export const pullLogs = (data) => {
    return dispatch => dispatch(actions.pullLogs(data))
}
export const appReln = (data) => {
    return dispatch => dispatch(actions.appReln(data))
}
export const getTenantList = (data) => {
    return dispatch => dispatch(actions.getTenantList(data))
}
export const fetchDeviceInventory = (data) => {
    return dispatch => dispatch(actions.fetchDeviceInventory(data))
}
export const updateDeviceInventory = (data) => {
    return dispatch => dispatch(actions.updateDeviceInventory(data))
}
export const allocateDevice = (data) => {
    return dispatch => dispatch(actions.allocateDevice(data))
}
export const getDeviceIdList = (data) => {
    return dispatch => dispatch(actions.getDeviceIdList(data))
}
export const updateDeviceByDevice = (data) => {
    return dispatch => dispatch(actions.updateDeviceByDeviceId(data))
}
export const updateDeviceByUser = (data) => {
    return dispatch => dispatch(actions.updateDeviceByUser(data))
}
export const getDeviceId = (data) => {
    return dispatch => dispatch(actions.getDeviceId(data))
}
export const setDeviceId = (data) => {
    return dispatch => dispatch(actions.setDeviceId(data))
}

export const setSearchedText = (data) => {
    return dispatch => dispatch(actions.setSearchedText(data))
}
export const setFileteredData = (data) => {
    return dispatch => dispatch(actions.setFileteredData(data))
}
export const fetchConfigurationByDeviceId = (data) => {
    return dispatch => dispatch(actions.fetchConfigurationByDeviceId(data))
}
export const restConfigDevices = (data) => {
    return dispatch => dispatch(actions.restConfigDevices(data))
}
export const fetchDeviceDashboard=(data)=>{
    return dispatch=>dispatch(actions.fetchDeviceDashboard(data))
}
export const fetchDeviceInfo=(data)=>{
    return dispatch=>dispatch(actions.fetchDeviceInfo(data))
}
export const getFindMyDeviceEvent=(data)=>{
    return dispatch=>dispatch(actions.getFindMyDeviceEvent(data))
}